import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import axios from "axios";

import {retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host, makeReadableDate, orderExercise, validateEmail} from "./main.js";

import {
	Link
} from 'react-router-dom';


class CreateAccount extends React.Component {
	constructor(props) {
		super(props);

        // this.titles = ["Get Total With Weeks, Days, and Regimens", "Get Total With 2 Dates", "Get Total of Entire Regimens"];

		this.state = {
            username: "",
            email: "",
            password: "",
            response: "",
            createAccount: true
		};
	
        this.handleInputChange = this.handleInputChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.handleKeys = this.handleKeys.bind(this);

	}

	handleInputChange(event) {
		console.log(event)
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
            console.log(value)
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}
        console.log(typeof value)

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value
		}, function () {
            self = this;
            console.log(this.state);
            // this.makeRequest();
        });
	}

    makeRequest() {
        var self = this;
        var dataToSend = {
            name: this.state.username,
            email: this.state.email,
            password: this.state.password,
        };

        if (!this.state.createAccount) {
            if (!validateEmail(this.state.username)) {
                dataToSend.email = undefined;
            } else {
                dataToSend.name = null;
                dataToSend.email = this.state.username;
            }
        }

        
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/${this.state.createAccount ? "createAccount" : "login"}`, 
        dataToSend, 
            {withCredentials: true}
        )
        .then(function (response) {
            
            console.log(response.data)
            window.location = "/";
            self.setState({
                response: <span>{response.data.info} <a href="/">Continue</a></span>
            })

             
        })
        .catch(function (error) {
            if( error.response ){
                console.log(error.response.data);  
                self.setState({
                    response: error.response.data.info
                })
            }
        })
    }

    handleKeys(e) {
        if (e.key === 'Enter') {
            this.makeRequest();
        }
    }

	render() {
        return (
            <div>
                <div className="App" 
				    style={{
					    // WebkitUserSelect: "none",
					    // MozUserSelect: "none",
					    // msUserSelect: "none",
					    // userSelect: "none",
				    }}
			    >
				    <MainPage title="" noNav={true}>
                        <h1>{(this.state.createAccount) ? "Create Account" : "Login"}</h1>
                        <p><a href="#" onClick={() => {
                            this.setState({
                                createAccount: !this.state.createAccount 
                            })
                        }}>{(this.state.createAccount) ? "Log in instead" : "Create account instead"}</a></p>
                        <table id="accountCreate">
                            <tbody>
                                <tr>
                                    <td style={{width: (this.state.createAccount) ? "20%" : "40%"}}>Username{(!this.state.createAccount) ? "/email" : ""}</td>
                                    <td><input type={"text"} onKeyUp={this.handleKeys}  name={"username"} id={"username"} value={this.state.username} style={{width: "90%"}} onChange={this.handleInputChange}/></td>
                                </tr>

                                {(this.state.createAccount) ?
                                        <tr>
                                            <td>Email</td>
                                            <td><input type={"email"} onKeyUp={this.handleKeys}  name={"email"} id={"email"} value={this.state.email} style={{width: "90%"}} onChange={this.handleInputChange}/></td>
                                        </tr>
                                    :""
                                }
                                
                                <tr>
                                  <td>Password</td>
                                  <td><input type={"password"} onKeyUp={this.handleKeys}  name={"password"} id={"password"} value={this.state.password} style={{width: "90%"}} onChange={this.handleInputChange}/></td>
                                </tr>
                            </tbody>

                            <tfoot>
                              <tr>
                                  <td><input type={"submit"} onKeyUp={this.handleKeys} name={"submit"} id={"submit"} onClick={this.makeRequest}/></td>
                              </tr>
                            </tfoot> 
                        </table>
                        <p>
                            {this.state.response}
                        </p>
                        <p><b>I'd advise checking out the development newer version at <a href={"https://development2.regimen.social/"}>development2.regimen.social.</a></b></p>
                        <p className="hide" style={{textAlign: "left"}}>
                            <b>If you're on a mobile phone, you may want to try this on a computer for a more fine-tuned experience</b>
                        </p>
                    </MainPage>
                </div>
            </div>
        )
	}
}

export { 
	CreateAccount
};