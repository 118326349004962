import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import RecordExercise from "./Reusable/RecordExercise.js";

import {retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host, makeReadableDate, orderExercise} from "./main.js";

import {
	Link
} from 'react-router-dom';



class App extends React.Component {
	constructor(props) {
		super(props);
		// this.state = {value: ''};
		this.mainPage = React.createRef();
		this.week = React.createRef();
		this.day = React.createRef();
		
		this.image = React.createRef();

		this.state = {
			day: 1,
			week: 1,
			regimen: "2021q1", regimenName: "resurrection",
			activeDate: false,
			date: '',
			message: "",
			hideImage: false,
			hideLog: false,
			skipSeven: false,
			info: "",
			weekInfo: "",
			fancyDate: "",
			imageDir: [],
			currentImage: 1,
			exerciseImage: 1,
			exists: false,
			exercises: [
				// {type: "test", amount: 50, amountround: 25, minute: 0, per: "", rounds: 2, second: 0}
			]
		};
	  
	
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputChangeSpecific = this.handleInputChangeSpecific.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.getDay = this.getDay.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);


	}

	handleImageChange(image) {
		console.log(image)
		this.setState({
			currentImage: image
		});
	}

	handleKeyPress (event) {
		// if(event.key === 'Enter'){
		  console.log('enter press here! ')
		// }
	}

	handleInputChangeSpecific(id, value, name) {
		console.log(id)

		if (id == "day") {
			if (value > (this.state.skipSeven ? 6 : 7) ) {
				value = 1;
				this.setState({
					week: this.state.week + 1
				});
			} else if (value < 1) {
				value = this.state.skipSeven ? 6 : 7;
				this.setState({
					week: this.state.week - 1
				});
			}
		}

		this.setState({
			[name]: value,
		  //   activeDate: true,
		}, () => {
		  this.getDay();
		});
	} 

	handleInputChange(event) { // CHANGE TO TAKE IN SPECIFIC VALUES
		console.log(event)
		const target = event.target;
		let value;
		if (target.type === "number") {
			value = parseInt(target.value);
		} else if (target.type === "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}
    	const name = target.name;

		if (name == "regimenName") {
			
			value = target.value.split("|")[1]

			this.setState({
				regimen: target.value.split("|")[0]
			})
		}

		if (target.id == "day") {
			if (value > (this.state.skipSeven ? 6 : 7) ) {
				value = 1;
				this.setState({
					week: this.state.week + 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			} else if (value < 1) {
				value = this.state.skipSeven ? 6 : 7;
				this.setState({
					week: this.state.week - 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			}
		}

    	this.setState({
		  [name]: value,
		//   activeDate: true,
		}, () => {
			if (name != "currentImage") {
				
				this.getDay();
			}
		});
	}

	getDay() {

		// console.log(dateFromWeekDay(this.state.day, this.state.week));

		if (!this.state.activeDate) {
			this.setState({
				date: dateFromWeekDay(this.state.regimen, this.state.day, this.state.week)
			})
		}

		retrieveDay(
			this.state.activeDate ? this.state.date : "", 
			this.state.regimenName, 
			this.state.week, 
			this.state.day
		).then((data) => {
			// console.log(data)
			
			

			data.data.exercises.sort(function(a, b) {
				var textA = a.type.toUpperCase();
				var textB = b.type.toUpperCase();
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			}).reverse();


			

			this.setState({
				exercises: orderExercise(data.data.exercises),
				regimenName: data.data.regimenName,
				info: data.data.info,
				weekInfo: data.data.weekInfo.info,
				day: data.data.day,
				date: data.data.date,
				week: data.data.week,
				regimen: data.data.regimen,
				imageDir: data.data.images,
				currentImage: data.data.activityImage,
				exerciseImage: data.data.activityImage,
				exists: true,
				message: `FOR DAY ${data.data.day}, ${['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'][data.data.day - 1].toUpperCase()}`
				
			}, () => {
				this.setState({
					fancyDate: makeReadableDate(this.state.date),
				})
			});

			this.mainPage.current.changeTitle(`Week ${this.state.week}, Day ${this.state.day}`);

		})
		
		.catch((data) => {
			// console.log(data)
			this.setState({
				message: `FOR DAY ${this.state.day}, ${['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'][this.state.day - 1].toUpperCase()}`,
				fancyDate: `No record for ${!this.state.activeDate ? `Week ${this.state.week} Day ${this.state.day}` : this.state.date}`,
				exercises: [],
				info: "",
				currentImage: 1,
				exerciseImage: 1,
				exists: false,
				imageDir: data.data ? data.data.images : [],
				weekInfo: ""
			}, () => {
				this.mainPage.current.changeTitle(this.state.fancyDate);
			});	
		})
		
		.then(() => {
			
			// console.log(this.state.exercises);
			if (!this.state.exists) {
				this.setState({
					exercises: [{type: "noRecord"}]
				});
			} else if (this.state.exercises.length == 0) {
				this.setState({
					exercises: [{type: "noActivity"}]
				})
			}
			// console.log("done")

		});
	}

	componentDidMount() {
		this.getDay()
	}

	render() {
  		return (
    		<div className="App daySelect" 
				style={{
					WebkitUserSelect: "none",
					MozUserSelect: "none",
					msUserSelect: "none",
					userSelect: "none",
				}}
			>
				<MainPage title="Week X, Day X" ref={this.mainPage}>
					<p><span style={{fontVariant: "small-caps"}}>{this.state.regimenName}</span></p>
					
					<ul style={{padding: "0", listStyle: "none", paddingLeft: "20px", minHeight: "45px"}}>
                        <li>
							<input type="checkbox" checked={this.state.hideImage} name="hideImage" id="hideImage" onChange={this.handleInputChange}/> <label htmlFor="hideImage">Hide Image</label> 
						</li>
						<li>
						<input type="checkbox" checked={this.state.hideLog} name="hideLog" id="hideLog" onChange={this.handleInputChange}/> <label htmlFor="hideLog">Hide Exercise Logging</label> 
						</li>
						<li>
							<input type="checkbox" checked={this.state.skipSeven} name="skipSeven" id="skipSeven" onChange={this.handleInputChange}/>  <label htmlFor="skipSeven">Skip Day 7</label> 
						</li>
                    </ul>
					
					<table id="division">
					  	<thead>
						  	<tr>
					    		<th>Retrieve with Week, Day, and Regimen</th>
					    		<th>Retrieve with Date</th> 
					  		</tr>
						</thead>
					  	<tbody>
						  	<tr>
								<td onClick={() => {
										this.setState({
											activeDate: false
										});
									}}>
									<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	            				<li key="weeks">
												Week: <input type="number" ref={this.week} value={this.state.week} name="week" style={{width: "100%", boxSizing: 'border-box'}} id="week" disabled={this.state.activeDate} onChange={this.handleInputChange} /><button onClick={() => this.handleInputChangeSpecific(this.week.current.id, this.state.week + 1, this.week.current.name)}>⬆️</button><button onClick={() => this.handleInputChangeSpecific(this.week.current.id, this.state.week - 1, this.week.current.name)}>⬇️</button>
        	            				</li>
        	            				<li key="days">
											
											
        	            				    	Day: <input type="number" ref={this.day} value={this.state.day} name="day"  style={{width: "100%", boxSizing: 'border-box'}} id="day" disabled={this.state.activeDate} onChange={this.handleInputChange} /> <button onClick={() => this.handleInputChangeSpecific(this.day.current.id, this.state.day + 1, this.day.current.name)}>⬆️</button><button onClick={() => this.handleInputChangeSpecific(this.day.current.id, this.state.day - 1, this.day.current.name)}>⬇️</button><button style={{width: "100%"}} onClick={() => {
													if (!this.timeInterval) {
														
														console.log("hi")
														this.timeInterval = setInterval(() => {
															
															if (!this.state.hideImage && this.image.current.complete || this.state.hideImage) {
																
																if (!this.state.exists) {
																	clearInterval(this.timeInterval);
																	this.timeInterval = null;
																	
																	this.handleInputChangeSpecific(this.day.current.id, this.state.day - 1, this.day.current.name)		
																} else {
																	this.handleInputChangeSpecific(this.day.current.id, this.state.day + 1, this.day.current.name)
																}
															}

															
														}, 200);
													} else {
														clearInterval(this.timeInterval);
														this.timeInterval = null;
													}
													
												}}>Sequence</button>
										</li>
        	            				<li key="regimens">
        	            				    <select id="whichRegimen" value={`${this.state.regimen}|${this.state.regimenName}`} name="regimenName"  style={{width: "100%"}} disabled={this.state.activeDate} onChange={this.handleInputChange}>
        	            				        <option value="2021q1|resurrection">2021q1: "RESURRECTION"</option>
        	            				        <option value="2020q4|incarnation">2020q4: "INCARNATION"</option>
        	            				    </select>
        	            				</li>
        	        				</div></ul>
								</td>
					    		<td onClick={() => {
										this.setState({
											activeDate: true
										});
									}}>
									<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	            				<li>Date: <input type="date" name="date" id="date" value={this.state.date} disabled={!this.state.activeDate} onChange={this.handleInputChange}/></li>
										<li><button disabled={!this.state.activeDate} onClick={() => {
											this.setState({
												date: (new Date()).toISOString().substring(0, 10)
											}, () => {
												this.getDay();
											})
											
										}}>Today</button></li>
									</div></ul>
								</td>
					  		</tr>
					  	</tbody>
					</table>
					{/* <p style={{display: this.state.hideImage ? "none" : "inline-block", }}><input type="range" name="currentImage" min={1} disabled={this.state.imageDir.length == 1} max={this.state.imageDir.length} id="imagePos" value={this.state.currentImage} onChange={this.handleInputChange} style={{width: (100)}}/>{((this.state.currentImage == this.state.exerciseImage) ? <b>{this.state.currentImage}</b> : this.state.currentImage)}/{this.state.imageDir.length}
					</p> */}
					
					{(!this.state.hideLog) ? <RecordExercise 
					regimen={this.state.regimen} day={this.state.day} week={this.state.week} 
					date={this.state.date} modelExercises={this.state.exercises} dayExists={this.state.exists} 
					/> : ""}
					

					{(!this.state.hideImage) ? 
						<span>
							<div id="nav">
								<ul id="switchImage">
									{(this.state.imageDir.length > 1) ? (
										this.state.imageDir.map((temp, index) => {
											return (<li key={index}><span className={`a ${(this.state.currentImage == (index + 1)) ? "active" : ""}`} onClick={() => this.handleImageChange(index+1)}>{index+1}</span></li>);	
										})
									) : (
										<li><span className={`a active`}>1 (only)</span></li>
									)}
								</ul>
							</div>
							{this.state.imageDir ? 
							<img ref={this.image} className={"regimenImage"} src={`${secure ? "https" : "http"}://${host}:${port}/${this.state.imageDir[this.state.currentImage-1]}`} width={"500px"} id={"regimenImage"} alt=""/> 
							: ""} 
						</span>
						: ""}

					<div id="dayMessage"
						style={{
							WebkitUserSelect: "text",
							MozUserSelect: "text",
							msUserSelect: "text",
							userSelect: "text",
						}}
					>
					<p><b>Week {this.state.week}; Day {this.state.day}</b> - {this.state.fancyDate}</p>
					{(this.state.weekInfo ? <p>"{this.state.weekInfo}"</p> : "")}
					{(this.state.info ? <p>"{this.state.info}"</p> : "")}
						<span>
							{this.state.message}
						</span>
						<ul id="exercises">
       						{this.state.exercises.map((temp) => {

								var originalSecond = temp.second;
								var newSecond = originalSecond % 60;
								var newMinute = ~~(originalSecond / 60);
								

								if (temp.amount && !newMinute && !newSecond) {
									return (<li key={temp.type}><b>{temp.amount} {returnPlural(temp.amount, allExercises[temp.type])}</b> ({temp.rounds} * {temp.amountround}{temp.per ? ` per ${temp.per}` : ""})</li>);
									
								} else if (originalSecond) {
									return (<li key={temp.type}><b>{temp.amount} {returnPlural(temp.amount, allExercises[temp.type])} of {(newMinute > 0) ? `${newMinute} ${returnPlural(newMinute, "minute")} ` : ""}{(newSecond > 0) ? `${newSecond} ${returnPlural(newSecond, "second")} ` : ""}</b></li>);
									// var newitem = `<b>${temp.amount} ${properName} of ${temp.minute ? `${temp.minute} minutes` : ""} ${temp.second ? `${temp.second} seconds` : ""}<b/>`;
								} else {
									return (<li key={temp.type}><b>{allExercises[temp.type] ? allExercises[temp.type] : temp.type}</b></li>)
								}
        					})}
      					</ul>
						
					</div>

					
					<div style={{userSelect: "text", WebkitUserSelect: "text"}}>
						<span id="daymessage"></span>
			
						<ul id="exerciseitems">

						</ul>
					</div>

					
					
				</MainPage>
				{/* <div id="mainSideBar">

				</div> */}
				<table id="exerciseresults"
					style={{
						WebkitUserSelect: "text",
						MozUserSelect: "text",
						msUserSelect: "text",
						userSelect: "text",
					}}
				>
    			    <thead>
    			        <tr>
    			            <th>Exercise</th>
    			            <th>Total Amount</th> 
    			            {/* <th>Minute</th> */}
    			            <th>Second</th>
    			            <th>Round</th>
    			            <th>Amount</th>
    			            <th>Per</th>
    			        </tr>
    			    </thead>
    			    <tbody>
						{this.state.exercises.map((r) => (
                    	  <tr key={r.type}>
                    	      <td>{returnPlural(r.amount, allExercises[r.type])}</td>
							  <td>{r.amount}</td>
							  {/* <td>{r.minute}</td> */}
							  <td>{r.second}</td>
							  <td>{r.rounds}</td>
							  <td>{r.amountround}</td>
							  <td>{r.per}</td>
                    	  </tr>
                    	))}
    			    </tbody>

    			</table>
    		</div>
	  	);
	}
}

export { 
	App
};
