import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import {links, user, retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host} from "./main.js";

import axios from "axios"

import {
	Link
} from 'react-router-dom';


class NavBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			links: links,
			useremail: user.email
		};
	


	}


	render() {
        return (
            <div>
                <div className="navBar" 
				style={{
				    WebkitUserSelect: "none",
				    MozUserSelect: "none",
				    msUserSelect: "none",
				    userSelect: "none"
				}}>
                    <ul>
                        {this.state.links.map((r) => (
                            <li className={(window.location.pathname.includes(r.link) ? "active" : "inactive")} key={r.link}><Link to={r.link}>{r.info}</Link></li>
                        ))}
						<li>
							<a className={"hideOnMobile"} href="#" onClick={() => {
								axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/logout`, 
								{
								}, 
									{
										withCredentials: true
									}
								).then(() => {
									window.location = "/";
								}).catch(() => {

								})
							}}>Logout</a>
						</li>
                    </ul>
                </div>
				{user.email ? <div style={{display: "block", textAlign: "right", margin: "6px", fontSize: "11pt"}}>Logged in as <b>{user.username}</b> ({user.email})</div> : ""}
            </div>
        )
	}
}

export default NavBar;
