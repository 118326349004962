import React, { useRef, useEffect } from 'react';
import MainPage from '../MainPage';
import equal from 'fast-deep-equal'

import axios from "axios";

import exerciseMain from "../sourceImages/exerciseMain.png";
import day7placeholder from "../sourceImages/day7placeholder.jpg";

import {connection, retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host, makeReadableDate, orderExercise, getReadableTime} from "../main.js";

import {
	Link
} from 'react-router-dom';


class RecordExercise extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
            startedDay: false,
            dayExists: false,
            restDay: false,
            bannerMessage: "notFound",
            myDay: {
                exercises: []
            },
            activeExercise: "",
            extraInfo: false,
            date: ""
		}

        this.presetMessages = {
            notFound: [
                "This day does not exist",
                "Sorry for any inconvenience"
            ],
            daySeven: [
                "This is Day 7",
                <span>Day 7 is <i>always</i> a rest day.</span>
            ],
            startDay: [
                "I'm doing today",
                <span><i>or</i> I need to log this day</span>
            ],
            restDay: [
                "This is a rest day",
                "There is nothing to do"
            ]
        }

        this.presetCovers = {
            startDay: <span>
                Actual information about the day would be put here.
			    <ul style={{marginTop: "2px", marginBottom: "2px"}}>
			    	<li>Maybe I'll list some stuff</li>
			    	<li>Another item</li>
			    	<li>If you're inspecting element, hello</li>
			    </ul>
			    Have to add some variation. I could totally put a photo here. But I might be pushing it...
			    <br/><br/>
			    <span className={"blur"} style={{width: "94.5px", height: "172px", backgroundImage: `url(${exerciseMain})`, float: "right"}}></span>
            </span>,
            daySeven: <span>
                <img src={day7placeholder} alt="" style={{filter: `blur(10px)`, opacity: "0.2"}}/>
            </span>
        }

        this.getModelDay = this.getModelDay.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

	}

    makeRequest(type = "check", fromSocket = false) {

		var self = this;
		var dataToSend = {
			week: this.props.week,
			day: this.props.day,
			regimen: this.props.regimen,
            date: this.state.date,
		}

		dataToSend.info = type;
		if (type == "update") {
            if (this.state.modelExercises && this.state.activeExercise && this.state.modelExercises[this.state.activeExercise]) {
                dataToSend.exercises = [
                    {
                        type: self.state.activeExercise,
                        amount: this.state.modelExercises[this.state.activeExercise].amountround,
                        second: this.state.modelExercises[this.state.activeExercise].second
                    }
                ]
            }
		}

        
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageExercise`, 
        dataToSend, 
            {
                withCredentials: true
            }
        )
        .then (function (response) {

            console.log("!HERE!");
            console.log(response)

            if (response.data.status != "ok") {
                throw "Dayn't started"
            }
			
            if (type == "check" || type == "create") {
				self.setState({
					startedDay: true
				})
			}

			if (response.data.data) {
				self.setState({
					myDay: response.data.data
				}, () => {
                    if (!self.state.modelExercises[self.state.activeExercise] && self.state.myDay.exercises && self.state.myDay.exercises[0]) {
                        
                        self.setState({
                            activeExercise: self.state.myDay.exercises[0].type
                        });
                    }
                });
			}

			if (type == "check") {
				self.setState({
					startedDay: true
				})
			}

            if (connection.state != "Connected" && type != "check") {
                self.makeRequest("check");
            }
        })
        .catch(function (error) {
			if (type == "check" || type == "delete") {
                console.log("ERGOJIERGOJIERGJOIERGFKI")
				self.setState({
                    myDay: {
                        exercises: []
                    },
					startedDay: false
				})
			}
        })
    }

    getModelDay() {
        
        if (this.props.modelExercises != null && this.props.date != null && this.props.dayExists != null) {


            var modelExercises = {}
			this.props.modelExercises.forEach(e => {
				modelExercises[e.type] = {
					type: e.type,
					amount: e.amount,
					amountround: e.amountround > 0 ? e.amountround : 1,
					per: e.per,
					rounds: e.rounds,
					second: e.second
				};
			});

            // var bannerMessage = this.props.dayExists ? this.props.day != 7 ? ((this.props.modelExercises.length == 0) ? "restDay" : "startDay") : "daySeven" : "notFound";

            var restDay = false;
            var bannerMessage = "notFound";
            if (this.props.dayExists) {
                if (this.props.day == 7) {
                    bannerMessage = "daySeven";
                } else {
                    if ((this.props.modelExercises.length == 0 || this.props.modelExercises[0].type == "noActivity")) {
                        bannerMessage = "restDay";
                        restDay = true;
                    } else {
                        bannerMessage = "startDay";
                    }   
                } 
            }

            this.setState({
                modelExercises,
                date: this.props.date,
                dayExists: this.props.dayExists,
                restDay,
                week: this.props.week,
                day: this.props.day,
                bannerMessage
            }, () => {
                this.makeRequest("check");
            })
        } else {
            
			var self = this;
        	axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveDay`, 
        	{
				week: self.props.week,
				day: self.props.day,
				regimen: self.props.regimen,
                date: self.props.date
			}, 
        	    {
        	        withCredentials: true
        	    }
        	)
        	.then (function (response) {
                if (response.status == 200) {
                    
                    var modelExercises = {}
			        response.data.data.exercises.forEach(e => {
			        	modelExercises[e.type] = {
			        		type: e.type,
			        		amount: e.amount,
			        		amountround: e.amountround > 0 ? e.amountround : 1,
			        		per: e.per,
			        		rounds: e.rounds,
			        		second: e.second
			        	};
			        });

                    var restDay = false;
                    var bannerMessage = "notFound";
                    if (response.data.data.day == 7) {
                        bannerMessage = "daySeven";
                    } else {
                        if ((response.data.data.exercises.length == 0)) {
                            bannerMessage = "restDay";
                            restDay = true;
                        } else {
                            bannerMessage = "startDay";
                        }   
                    } 
                    

                    self.setState({
                        modelExercises,
                        date: response.data.data.date,
                        dayExists: true,
                        restDay,
                        week: response.data.data.week,
                        day: response.data.data.day,
                        bannerMessage
                    }, () => {
                        self.makeRequest("check");
                    })
                } else {
                    throw "Not found";
                }
            }).catch(() => {
                self.setState({
                    modelExercises: [],
                    date: "",
                    dayExists: false,
                    week: 1,
                    day: 1,
                    bannerMessage: "notFound"
                }, () => {
                    self.makeRequest("check");
                })
            })
        }

        
    }

    componentDidUpdate(prevProps) {
		if (!equal(prevProps, this.props)) {
			this.getModelDay();

            if (this.props.week < 1) {
                this.setState({
                    dayExists: false
                })
            }
            
            
		}
	}

    handleWebSocket(message, type) {
        if (type == "myExercise") {
            if (message.date == this.state.date) {
                
                if (message.fullDay) {
                    console.log(message.fullDay)
                    this.setState({
                        startedDay: true,
                        myDay: message.fullDay,
                        bannerMessage: "startDay"
                    }, () => {
						if (!this.state.activeExercise && this.state.myDay.exercises) {
							
							this.setState({
								activeExercise: this.state.myDay.exercises[0].type
							});
						}
                    });
                } else {
                    this.setState({
                        startedDay: false,
                        bannerMessage: "startDay",
                        myDay: {
                            exercises: []
                        },
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.getModelDay();

        connection.on("myExercise", (message) => this.handleWebSocket(message, "myExercise"));
        
    }

    componentWillUnmount() {
        

        this.handleWebSocket = (message, type) => {};
    }

    handleInputChange(event) {
		
		const target = event.target;
		var value;

		if (target.type != "checkbox") {
			this.setState({
				updated: ""
			})
		}

		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value
		});
	}

	render() {
        return (
            <div id={"myExercise"} className={(this.state.startedDay) ? "hasStarted" : ""} style={{
                cursor: (!this.state.startedDay && this.state.dayExists && !this.state.restDay && (this.props.day != 7)) ? "pointer" : this.state.startedDay ? "unset" : "not-allowed"
            }} onClick={() => {
                if (!this.state.startedDay && this.state.dayExists && !this.state.restDay  && (this.props.day != 7)) {
                    
                    this.makeRequest("create");
                }
            }}>
                {this.state.startedDay && this.state.bannerMessage != "restDay" ?
                    <span>
                        <div style={{padding: "20px"}}>
							<b>Week {this.props.week}; Day {this.props.day}</b> - {makeReadableDate(this.state.myDay.date)} ({(this.state.myDay.complete) ? <b>Complete ✓</b> : "Incomplete"})
							<div>You've done: </div>
							<ul style={{margin: "0", listStyle: "none", paddingLeft: "20px"}} className={"myExerciseList"}>
								{(this.state.myDay.exercises.map((r) => {

									if (this.state.modelExercises[r.type]) {
										var modelExercise = this.state.modelExercises[r.type];
										var possibleTime = "";
										var time = "";
										var bold = {};

										if (modelExercise.second) {
											possibleTime = `; ${getReadableTime(((modelExercise.amount * modelExercise.second) - r.second), true)} left`
											time = ` of ${getReadableTime(modelExercise.second)} `;
										}

										var remainder = `(${modelExercise.amount - r.amount} to go${possibleTime})`;

										if (r.amount < modelExercise.amount) {
											bold = {
												fontWeight: "bold"
											}
										} else {
											bold = {
												fontStyle: "italic"
											}
											remainder = "(all done) ✓"
										}

										return <li onClick={() => {
											this.setState({
												activeExercise: r.type
											})
										}} key={r.type} style={bold}><input type={"radio"} checked={this.state.activeExercise == r.type} readOnly={true}/> {r.amount} {returnPlural(r.amount, allExercises[r.type])}{time} {remainder} </li>
									}
								}))}
							</ul>
							<p>
							
							<select style={{height: "50px"}} id="activeExercise" name="activeExercise" value={this.state.activeExercise} onChange={this.handleInputChange}>
								{
									this.state.myDay.exercises ? 
									this.state.myDay.exercises.map((r, i) => {
										return <option key={i} value={r.type}>{allExercises[r.type]}</option>;
									}) : ""
								}
							</select>{(this.state.modelExercises[this.state.activeExercise]) ? <button disabled={!(this.state.activeExercise)} style={{height: "50px"}} onClick={() => this.makeRequest("update")}>{(this.state.activeExercise) ? "Add " + `${this.state.modelExercises[this.state.activeExercise].amountround}${this.state.modelExercises[this.state.activeExercise].second ? ` (${getReadableTime(this.state.modelExercises[this.state.activeExercise].second, true)})` : ""}` : "Select exercise"}</button> : "Please select an exercise"}</p><p><i>{this.state.updated}</i></p>
							<label htmlFor="extraInfo">Show more info</label> <input type="checkbox" checked={this.state.extraInfo} name="extraInfo" id="extraInfo" onChange={this.handleInputChange}/>
							{(this.state.extraInfo) ? (
								<div>
									<button onClick={() => {
                                        this.makeRequest("check");
                                    }}>Refresh</button> <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.makeRequest("delete")}>Delete day</span> 
                                </div>
							) : ""}
						</div>
                    </span>
                :  
                    <span className={"exerciseCover"} >
                        {this.presetMessages[this.state.bannerMessage].map((item, index) => {
                            return (
                                <div key={index} className={"vCentre textChange"}>
                                    <span>{item}</span>
                                </div>
                            )
                        })}
                        {(this.state.bannerMessage == "startDay") ? this.cover : ""}
                        <div id="cover">
                            {this.presetCovers[this.state.bannerMessage] ? this.presetCovers[this.state.bannerMessage] : ""}
                        </div>
                    </span>
                }
				
				
			</div>
        )
	}
}

export default RecordExercise;