import MainPage from "./MainPage";
import NavBar from "./NavBar";

function About(props) {
    return (
        <MainPage title="About">
            <p>rS-prototype is a development interface and prototype of <a href="https://regimen.social/"><b>regimensocial</b></a>. If you're reading this, you're likely one of the lucky few who I've shared this with. <b>regimensocial</b> gets closer everyday and you can view all the features that work on this site.</p>
            <p>I'm hoping to have a working version with most of the features out by the end of the month, May.</p>
            <p>Technical information: this website's frontend is made in React. The backend is made with ASP.NET Core, my first time trying out the framework.</p>
            <p>If you know me, please let me know how you found the website. - Love and hugs, Jamie.</p>
            <hr/>
            <div><b>Update 8 September 2021</b></div>
            <div>The above text was written in late April.</div>
            <p>It is September now. I got a working version out on 29 June. About a month's delay. I am now working on the proper version. It should be ready by mid to late September. <b><a href={"https://development2.regimen.social/"}>It looks so much better.</a></b></p>
        </MainPage>
        
    );
}

export default About;