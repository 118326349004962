import React from 'react';

import {
	Link
} from 'react-router-dom';

import logo from './sourceImages/logo.svg'; 

import NavBar from "./NavBar";

import axios from "axios"

import {user, makeReadableDate, serverInfo, port, secure, host} from "./main";

class MainPage extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = {value: '', title: props.title};
  
	  this.handleChange = this.handleChange.bind(this);
	  this.handleSubmit = this.handleSubmit.bind(this);
	  this.changeTitle = this.changeTitle.bind(this);
	}
  
	handleChange(event) {
	  	this.setState({value: event.target.value});
	  	
	}

	changeTitle(newtitle) {
		console.log(newtitle);
		this.setState({title: newtitle});
		document.title = `${newtitle}${!serverInfo.production ? " (DEV)" : ""}`;
	}

	componentDidMount() {
		if (this.props.title) {
			document.title = this.props.title + `${!serverInfo.production ? " (DEV)" : ""}`
		}
	}
  
	handleSubmit(event) {
	//   alert('Your favourite flavour is: ' + this.state.value);
	  event.preventDefault();
	}
  
	render() {
	    return (
			<>
				<div id="main">
					
            	    <h1>
            	        {this.state.title} 
            	    </h1>
					{/* {(!this.props.noNav) ? 
						<NavBar/>
						: "" 
					} */}
					<div>
            	        {this.props.children}
            	    </div> 
					
					<span style={{textAlign: "right", fontStyle: "italic"}}><p><b>rS-prototype "{serverInfo.prodname}" ({serverInfo.production ? serverInfo.proddate : `${makeReadableDate(new Date())} DEV` })</b><br/>[Server "{serverInfo.serverversion}"] (<a href="#" onClick={() => {
					axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/logout`, 
					{
					}, 
						{
							withCredentials: true
						}
					).then(() => {
						window.location = "/";
					}).catch(() => {
						
					})
				}}><b>Logout</b></a>)</p></span>
            	</div>
			</>
            
	    );
	}
}

export default MainPage;