import axios from "axios"
import * as appSettings from "./settings.json";

import * as signalR from "@microsoft/signalr";


var serverInfo = {
    production: false,
    prodname: "",
    clientVersion: "",
    serverversion: "Unknown"
}

if (window.location.hostname.includes("192.168") || window.location.hostname.includes("localhost")) {
    serverInfo.production = false;
} else {
    serverInfo.production = true;
}

serverInfo.prodname = appSettings.clientVersion;
serverInfo.proddate = appSettings.clientDate;
var host;
var port;
var secure;
var linux = appSettings.linux;
var linuxhost = "192.168.1.14";

var user = {

}

var regimenNames = {
    "2020q4": "incarnation",
    "2021q1": "resurrection"
}

var links = [
    {link: "/about", info: "Regimen"},
    {link: "/sessions", info: "Sessions"},
    {link: "/totals", info: "Totals"},
    {link: "/imaging", info: "Imaging"},
    {link: "/social", info: "Friends"},
    {link: "/groups", info: "Groups"},
    {link: "/info", info: "About"},
]

if (serverInfo.production) {
    host = appSettings.production.host;
    port = appSettings.production.port;
    secure = appSettings.production.secure;
} else {
    if (linux) {
        port = appSettings.devLinux.port;
        host = appSettings.devLinux.host;
        secure = true;
        if (appSettings.devLinux.securePort) {
            port = appSettings.devLinux.securePort;
        }
    } else {
        host = window.location.hostname;
        port = appSettings.development.port;
        secure = appSettings.development.secure;
        if (appSettings.development.securePort) {
            port = appSettings.development.securePort;
        }
    }
}

let connection = new signalR.HubConnectionBuilder()
    .withUrl(`${secure ? "https" : "http"}://${host}:${port}/centralHub`)
    .build();

function tryStart() {
    connection.start().then(function () {
        restartCount = 1
        clearInterval(restartInterval);
        restartInterval = null;
        console.log(connection.state);
    }).catch(function (err) {
        if (restartCount > 24) {
            restartCount = 1
            clearInterval(restartInterval);
            restartInterval = null;
            console.log("Could not restart connection, 24 attempts. Goodnight.");
        } else {
            console.log(`[${restartCount}/24] Attempting restart in 5 seconds`);
            restartCount++;
        }
        
        return console.error(err.toString());
    });
}

var restartCount = 1;
var restartInterval;

connection.onclose(() => {
    restartInterval = setInterval(() => {
        tryStart()
    }, 5000);
});

tryStart();

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


function readFileDataAsBase64(e) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}

function dateFromWeekDay(regimen, day, week) {
    var main;

    if (!day) {
        day = 0;
    }

    if (!week) {
        week = 0;
    }

    console.log(regimen)
    if (regimen == "2020q4") {
        main = new Date("2020-10-19");
        if (week > 9) {
            return (new Date().toISOString().substring(0, 10));
        }
    } else if (regimen == "2021q1") {
        main = new Date("2021-01-04");
        if (week > 12) {
            return (new Date().toISOString().substring(0, 10));
        }
    } 



    main.setDate(main.getDate() - 1);
    

    main.setDate(main.getDate() + ((week-1)*7) + day);

    console.log(main)
    return (main.toISOString().substring(0, 10));
    

    // could make this work with the database instead but might just be unnecessary
}

function makeReadableDate(date, shorten) {

	if (date) {
		
		let readableDate = new Date(date);

        var month = readableDate.toLocaleString('default', { month: 'long' });
        if (!shorten) {
            month = month.substring(0, 3);
        }
		return (`${readableDate.getDate()} ${month} ${readableDate.getFullYear()}`)
	} else {
		return ("No record");
	}

}

function getReadableTime(second, short = false) {
    var originalSecond = second;
	var newSecond = originalSecond % 60;
	var newMinute = ~~(originalSecond / 60);
    var originalMinute = newMinute;
    newMinute = originalMinute % 60;
    var newHour = ~~(originalMinute / 60);
    var minute = !short ? "minute" : "min";
    var hour = !short ? "hour" : "hr";
    var second = !short ? "second" : "sec";
    return `${(newHour) ? `${newHour} ${returnPlural(newHour, hour)} ` : ""}${(newMinute) ? `${newMinute} ${returnPlural(newMinute, minute)} ` : ""}${(newSecond) ? `${newSecond} ${returnPlural(newSecond, second)} ` : ""}`.trim()
}


function retrieveDay(date, regimenName, week, day) {
    console.log(regimenName)
    return new Promise((resolve, reject) => {
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveDay`, 
        {
            date,
            regimenName,
            day,
            week
        }, 
            {withCredentials: true}
        )
        .then(function (response) {
            console.log("here!!!")
            console.log(response.data)
            resolve(response.data);
        })
        .catch(function (error) {
            if (error.response && error.response.data) {
                reject(error.response.data);
            } else {
                reject(error);
            }
            
        })
    });
}

var allExercises = {
    "unset": "Unset",
    "planks": "Plank", 
    "sidePlanks": "Side Plank", 
    "wallSits": "Wall Sit", 
    "bicycleCrunches": "Bicycle Crunch",  
    "lunges": "Lunge",  
    "squats": "Squat",  
    "legsElevatedPushUps": "Legs-elevated Push-up", 
    "burpees": "Burpee", 
    "regularPushUps": "Push-up",  
    "sitUps": "Sit-up", 
    "noRecord": "No record :/",
    "noActivity": "No activity :)"
};

for (const [key, value] of Object.entries(allExercises)) {
    console.log(`${key}: ${value}`);
}


var order = ["wallSits", "squats", "legsElevatedPushUps", "regularPushUps", "bicycleCrunches", "sidePlanks", "planks", "lunges", "sitUps", "burpees"];
            
function returnPlural(amount, properName) {
    if (amount > 1 || amount == 0) {
        if (properName.endsWith("ch")) {
            return properName + "es";
        } else {
            return properName + "s";
        }
    } else {
        return properName;
    }
}

function orderExercise (exerciseArray) {
    return exerciseArray.sort( function (a, b) {
        var A = a["type"], B = b["type"];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        } else {
            return -1;
        } 
    });
}

export {
    connection,
    host,
    port,
    secure,
    retrieveDay,
    allExercises,
    returnPlural,
    dateFromWeekDay,
    makeReadableDate,
    orderExercise,
    serverInfo,
    getReadableTime,
    user,
    validateEmail,
    links,
    readFileDataAsBase64,
    regimenNames
}