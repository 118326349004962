import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import logo from './sourceImages/logo.svg'; 
import {
  	BrowserRouter as Router,
  	Switch,
  	Route,
  	Link,
	Redirect
} from 'react-router-dom';

import './fancy.scss'; // this is the disabled styling

import {
	App
} from './App';

import {
	Total
} from './Total';

import {
	Social
} from './Social';

import Profile from "./Profile";

import {CreateAccount} from "./CreateAccount";

import {user, secure, host, port, serverInfo} from "./main";
import axios from "axios";


import MainPage from "./MainPage";
import { Imaging } from './Imaging';
import About from "./About";
import SideBar from "./SideBar";
import Groups from "./Groups";
import NavBar from './NavBar';
import SocialExercise from "./SocialExercise";
import UserContextProvider, { UserContext } from './contexts/UserContext';
import Sessions from './Sessions';

var loggedIn;
const Page404 = (() => {
	// window.location = "/about";
	return (
		<MainPage>
			Not exists.
		</MainPage>
	)
	
})


document.getElementById('root').innerText = "Connecting";
axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/test`, 
{
}, 
    {withCredentials: true}
)
.then(function (response) {
	
	
	
	serverInfo.serverversion = response.data.version;
	loggedIn = Boolean(response.data.data)
	ReactDOM.render(
		<React.StrictMode>
		  	<Router>
		  		{(loggedIn) ? 
				  	<Switch>
						<UserContextProvider>
							<SideBar test={"test"}/>
							<img src={logo} id={"headImage"} style={{
					    		WebkitUserSelect: "none",
					    		MozUserSelect: "none",
					    		msUserSelect: "none",
					    		userSelect: "none",
				    		}}/>
							<Route exact path="/about">
								<App />
							</Route>
							<Route exact path="/totals">
								<Total />
							</Route>
							<Route exact path="/imaging">
								<Imaging />
							</Route>
							<Route exact path="/info">
								<About />
							</Route>
							<Route exact path="/social/:friendName?" component={Social} />
							<Route exact path="/you">
								<Profile />
							</Route>
							<Route exact path="/sessions/:date?" component={Sessions} />
							<Route exact path="/groups/:groupName?" component={Groups} />
							
							<Route exact path="/" render={() => (
							    <Redirect to="/about"/>
							)}/>
						</UserContextProvider>
					</Switch>
				:
					<Switch>
						<Route exact path="/">
							<CreateAccount/>
						</Route>
						
						<Redirect to="/" />
					</Switch>

				}
		  </Router>
		</React.StrictMode>,
		document.getElementById('root')
  );
})
.catch(function (error) {
	document.getElementById('root').innerHTML = `An error occured accessing the API ${(!serverInfo.production) ? `<a href="${`${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=2021q1&day=1&week=1&number=1`}">Approve certificate.</a>` : ""}`;
    if (error.response && error.response.data) {
    } else {
    }
})



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
