import React, { createContext, Component } from "react";

import axios from "axios"

import {connection, secure, port, host, user, links} from "../main"

export const UserContext = createContext();

class UserContextProvider extends Component {

    constructor(props) {
		super(props);
        this.state = {
            username: "you",
            email: "",
            timestamp: "2005-03-10",
            picture: "",
            pictureCount: 0,
            bio: "",
            friends: [],
            privacySettings: 0,
            groups: [],
            links: links,
            session: {},
            sessionRequests: {},
            currentSessionName: ""
            
        }

        this.main = [];
        this.updateState = this.updateState.bind(this);
	}
    
    componentDidMount() {
        var self = this;

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveFriends`, 
        {
            action: "check"
        }, 
            {withCredentials: true}
        )
        .then(function (response) {
            self.setState({
                friends: response.data.data
            })
        })
        .catch(function (error) {
        })

        connection.on("friendInfoUpdate", function (message) {
            console.log(message)

            var newFriends = self.state.friends;
            // message.picture = message.picture + `&ic=${Math.random()}`;
            console.log(message.picture);
            newFriends[newFriends.findIndex(e => e.userUsername === message.name)].friend = message;
            self.setState({
                friends: newFriends
            });
        });
        
        connection.on("friendRequest", function (message) {
            console.log(message)

            axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveFriends`, 
            {
                action: "check"
            }, 
                {withCredentials: true}
            )
            .then(function (response) {
                console.log(response.data);
                self.setState({
                    friends: response.data.data
                });

            })
            .catch(function (error) {
            })

            
        });

        connection.on("joinMySession", function (message) {
            console.log("joinMySession");
            console.log(message);
            var reqSessions = Object.keys(self.state.sessionRequests).length ? self.state.sessionRequests : {};
            
            if (message.action != "over") {
                reqSessions[message.uuid] = message;
            } else if (message.action == "over" && reqSessions[message.uuid] && reqSessions[message.uuid].action) {
                delete reqSessions[message.uuid];
            }

            console.log(reqSessions);

            self.setState({
                sessionRequests: reqSessions
            })

        });

        connection.on("updateSession", function (message) {
            console.log("updateSession");
            console.log(message)
            self.setState({
                session: message
            })

        });

        connection.on("profileUpdate", function (message) {
            console.log("profileUpdate");
            console.log(message);
            self.setState({
                timestamp: message.timestamp,
                picture: message.picture,
                bio: message.bio,
                privacySettings: message.privacySettings,
                pictureCount: message.pictureCount,
                groups: message.groups
            });

        });

        connection.on("test", function (message) {
            console.log(message)

            
            
        });

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/whoami`, 
	    {
	    }, 
	        {withCredentials: true}
	    )
	    .then(function (response) {

            console.log(response.data.data);
            var templinks = links;

            if (!templinks.find(i => i.info === "@"+response.data.data.name)) {
                
                templinks.push({link: "/you", info: "@"+response.data.data.name})
            }
            

            self.setState({
                email: response.data.data.email,
                username: response.data.data.name,
                timestamp: response.data.data.timestamp,
                picture: response.data.data.picture,
                bio: response.data.data.bio,
                privacySettings: response.data.data.privacySettings,
                pictureCount: response.data.data.pictureCount,
                groups: response.data.data.groups,
                links: templinks
            });
	    }).catch(() => {
            
	    });

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageSession`, 
	    {
            action: "view"
	    }, 
	        {withCredentials: true}
	    )
	    .then(function (response) {

            console.log(response.data);

            self.setState({
                session: response.data.data
            })
            
	    }).catch(() => {
            
	    });

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageSession`, 
	    {
            action: "viewRequests"
	    }, 
	        {withCredentials: true}
	    )
	    .then(function (response) {

            console.log(response.data);

            self.setState({
                sessionRequests: response.data.data
            })
            
	    }).catch(() => {
            
	    });
    }

    componentWillUnmount() {
        this.main = [undefined, undefined];
    }

    updateState(name, value, callback) {
        this.setState({
            [name]: value
        }, () => {
            if (callback) {
                callback();
            }
        })
    }
    render() { 
        return (
            <UserContext.Provider value={{...this.state, updateState: this.updateState}}> 
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
 
export default UserContextProvider;