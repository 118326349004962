import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';
import { generatePath } from "react-router";


import axios from "axios";

import {connection, port, secure, host, makeReadableDate, user } from "./main.js";

import { DateTime } from "luxon";

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";

class Social extends React.Component {

    static contextType = UserContext;

	constructor(props) {
		super(props);
        this.state = {
            friend: "",
            response: "",
            requests: [],
            myRequests: [],
            friendInfo: <div></div>,
            currentFriend: "",
            currentArea: "friends"
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.retrieveRequests = this.retrieveRequests.bind(this);
        this.handleFriendClick = this.handleFriendClick.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);

	}

    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value
		}, function () {
            self = this;
            // this.makeRequest();
        });
	}

    retrieveRequests(action = "check", updateCurrentFriend = false) {
        var self = this;
        var dataToSend = {
            action
        };
        
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveFriends`, 
        dataToSend, 
            {withCredentials: true}
        )
        .then(function (response) {
            console.log(response.data);
            if (action == "requests") {
                self.setState({
                    requests: response.data.data
                })
            } else if (action == "myRequests") {
                self.setState({
                    myRequests: response.data.data
                })
            } else if (action == "check") {
                self.context.updateState("friends", response.data.data)
            }
            
            if (updateCurrentFriend) {
                self.handleFriendClick(self.state.friend, self.state.currentArea, false)
            }

        })
        .catch(function (error) {
        })
    }

    makeRequest(action = "check") {
        var self = this;
        
        var dataToSend = {
            friendUsername: this.state.friend,
            action
        };

        this.props.history.push({
            pathname: generatePath(this.props.match.path, {friendName: this.state.friend})
        });
        
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/specificFriend`, 
        dataToSend, 
            {withCredentials: true}
        )
        .then(function (response) {
            
            console.log(response)

            if (response.status == 204) {
                self.setState({
                    response: "You haven't sent a friend request to " + self.state.friend
                })
            
            } else if (response.status == 201) {
                self.setState({
                    response: "Sent a friend request to " + self.state.friend
                })
            } else {
                if (response.data.data.requited) {
                    self.setState({
                        response: "You've been friends with " + self.state.friend + " since " + DateTime.fromISO(response.data.data.timestamp).toRelative()
                    })
                } else {
                    self.setState({
                        response: self.state.friend + " is yet to accept your friend request"
                    })
                }
                


            }

             
        })
        .catch(function (error) {
            if( error.response ){
                if (action == "check") {
                    self.setState({
                        response: "User likely doesn't exist"
                    })
                } else if (action == "makeFriend") {
                    if (error.response.status == 409) {
                        self.setState({
                            response: "You can't add yourself"
                        })
                    } else {
                        self.setState({
                            response: "You've likely already added this person"
                        })
                    }
                    
                }
                
            }
        })
    }

    handleFriendClick(user, area, change = true) {
        if (change) {
            this.setState({
                currentArea: area
            })
        }

        this.props.history.push({
            pathname: generatePath(this.props.match.path, {friendName: user})
        });
        

        console.log(user)
        var userInfo;
        console.log(area);
        if (area == "friends") {
            userInfo = this.context.friends.find(e => e.userUsername === user)
        } else if (area == "requests") {
            userInfo = this.state.requests.find(e => e.userUsername === user)
        } else if (area == "myRequests") {
            userInfo = this.state.myRequests.find(e => e.friendUsername === user)
        }
        
        console.log("doing it right");
        console.log(userInfo);
        
        

        var randomNumber = new Date().getTime();
        
        console.log("imagechange " + randomNumber);
        this.setState({
            friend: change ? user : this.state.friend,
            fullFriendInfo: userInfo
        })
    }

    handleWebSocket(message, type) {
        if (type == "friendRequest") {
            console.log(message)
            this.retrieveRequests("requests", true);
            this.retrieveRequests("myRequests", true);
        } else if (type == "friendInfoUpdate") {
            console.log(message)
            //delay problem
            this.handleFriendClick(this.state.friend, this.state.currentArea, false);
        }
    }

    componentDidMount() {
        if (this.props.match.params.friendName) {
            this.setState({
                friend: this.props.match.params.friendName
            }, () => {
                this.handleFriendClick(this.props.match.params.friendName, this.state.currentArea, false)
            })
        }

        this.retrieveRequests("check");
        this.retrieveRequests("requests");
        this.retrieveRequests("myRequests");

        connection.on("friendRequest", (message) => this.handleWebSocket(message, "friendRequest"));
        connection.on("friendInfoUpdate", (message) => this.handleWebSocket(message, "friendInfoUpdate"));
    }

    componentWillUnmount() {
        

        this.handleWebSocket = (message, type) => {};
    }

	render() {
        
        return (
            
            <div>
                <div className="App"
				    style={{
					    // WebkitUserSelect: "none",
					    // MozUserSelect: "none",
					    // msUserSelect: "none",
					    // userSelect: "none",
				    }}
                    className="social"
			    >
				    <MainPage title="Social">
                        <p>Your friends {this.context.username ? <span> (you're <b>{this.context.username}</b>)</span> : ""} <input type="text" value={this.state.friend} onChange={this.handleInputChange} id="friend" name="friend" placeholder="Enter a username here"/> <button onClick={() => this.makeRequest()}>Check</button> <button onClick={() => this.makeRequest("makeFriend")}>Add</button> <button onClick={() => this.makeRequest()}>Check</button> <button onClick={() => this.makeRequest("endFriendship")}>Remove</button></p> 
                        <div id="friends" style={{padding: "20px"}}>
                            {this.context.friends.length ? <>
                                All your friends:
                                <ul>
                                    {this.context.friends.map((r, i) => <li key={i} onClick={() => this.handleFriendClick(r.userUsername, "friends")}>You've been friends with <b>{r.userUsername}</b> since {DateTime.fromISO(r.timestamp).toRelative()}</li>)}
                                </ul>
                            </> : ""}
                            
                            {this.state.requests.length ? <>
                                People who've requested you:
                                <ul>
                                    {this.state.requests.map((r, i) => <li key={i} onClick={() => this.handleFriendClick(r.userUsername, "requests")}><b>{r.userUsername}</b> added you {DateTime.fromISO(r.timestamp).toRelative()}</li>)}
                                </ul>
                            </> : ""}

                            {this.state.myRequests.length ? <>
                                People who you've requested:
                                <ul>
                                    {this.state.myRequests.map((r, i) => <li key={i} onClick={() => this.handleFriendClick(r.friendUsername, "myRequests")}>You added <b>{r.friendUsername}</b> {DateTime.fromISO(r.timestamp).toRelative()}</li>)}
                                </ul>
                            </> : ""}

                            {!this.state.myRequests.length && !this.state.requests.length && !this.context.friends.length ? <b>You have no friends, add some above</b> : ""}
                        </div>
                        <p> <button onClick={() => {
                            this.retrieveRequests("check");
                            this.retrieveRequests("requests");
                            this.retrieveRequests("myRequests");
                        }}>Refresh</button> </p>
                        <p>{this.state.response}</p>
                        <div>
                            {(!!this.state.fullFriendInfo) && (
                                <div>
                                    <span>
                                        {this.state.fullFriendInfo.friend.name &&
                                            <p><b>{this.state.fullFriendInfo.friend.name}</b> {this.state.fullFriendInfo.friend.bio && `- "${this.state.fullFriendInfo.friend.bio}"`}</p>
                                        }
                                        {!!(this.state.fullFriendInfo.friend.groups && this.state.fullFriendInfo.friend.groups.length > 0) && (<div>In {this.state.fullFriendInfo.friend.groups.length} group{this.state.fullFriendInfo.friend.groups.length > 1 && "s"} 
                                            <ul style={{marginTop: "0"}}>
                                            {this.state.fullFriendInfo.friend.groups.map((e) => {
                                                return <li key={e}><Link to={"/groups/" + e}>{e}</Link></li>;
                                            })}
                                            </ul>
                                        </div>)}
                                        <span>{this.state.fullFriendInfo.requited ? "Friends since" : "Request sent"} {DateTime.fromISO(this.state.fullFriendInfo.timestamp).toRelative()}</span>
                                        
                                    </span>
                                    {this.state.fullFriendInfo.friend.picture ? 
                                        <img src={`${secure ? "https" : "http"}://${host}:${port}/images/profilePicture?uuid=${this.state.fullFriendInfo.friend.picture}&imageCount=${this.state.fullFriendInfo.friend.pictureCount}`} alt={"picture"}/>
                                    : ""}
                                </div> 
                            )}
                        </div>
                    </MainPage>
                </div>
            </div>
        )
	}
}

export { 
	Social
};