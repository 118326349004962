import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import axios from "axios";

import {readFileDataAsBase64, connection, port, secure, host, makeReadableDate, user } from "./main.js";

import { DateTime } from "luxon";

import userDefault from "./sourceImages/userDefault.svg"

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";

class Profile extends React.Component {

	
    static contextType = UserContext;

	constructor(props) {
		super(props);
        this.state = {
			message: "",
			image: "",
			editing: false,
			imagechange: 0,
			error: "",
			bio: "",
			privacySettings: 0
        }

		this.makeRequest = this.makeRequest.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
        var self = this;

		if (name == "privacySettings") {
			value = parseInt(value);
		}
		
    	this.setState({
		  [name]: value
		}, function () {
            self = this;
            // this.makeRequest();
        });
	}


	makeRequest() {
		var self = this;
		axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/whoami`, 
        {
			action: "update",
			picture: this.state.image,
			bio: this.context.bio != this.state.bio ? this.state.bio : "",
			privacySettings: this.state.privacySettings != this.context.privacySettings ? this.state.privacySettings : 0
		}, 
            {withCredentials: true}
        )
        .then(function (response) {
			console.log(response.data);
			
			self.setState({
				editing: false,
				image: ""
			}, () => {
				console.log("user")
				console.log(response.data.data);
				self.context.updateState("pictureCount", response.data.data.pictureCount)
				self.context.updateState("bio", response.data.data.bio)
				self.context.updateState("picture", self.context.picture)
				self.context.updateState("privacySettings", response.data.data.privacySettings)
			})

        })
        .catch(function (error) {
			if (error.response.status == 413) {
				self.setState({
					error: "Image must not exceed 5MB"
				});	
			}
        })
	}

	render() {
        console.log(this.context);
        return (
            <div>
                <div className="App"
				    style={{
					    WebkitUserSelect: "none",
					    MozUserSelect: "none",
					    msUserSelect: "none",
					    userSelect: "none",
				    }}
                    className="profile"
			    >
				    <MainPage title="Profile">
						
					<p style={{cursor: "pointer"}} onClick={() => {
						this.setState({
							editing: !this.state.editing,
							bio: this.context.bio,
							privacySettings: this.context.privacySettings
						})
					}}><u>{!this.state.editing ? "Edit profile" : "View profile"}</u></p>
						
						{!this.state.editing ? 
							<div id="profileSplit">
									<p>You, <b>{this.context.username}</b>, joined regimensocial <b>{DateTime.fromISO(this.context.timestamp).toRelative()}</b> ({DateTime.fromISO(this.context.timestamp).toFormat("TT, dd MMM yyyy")})</p>
									<p>Your email is <b>{this.context.email}</b></p>
									<p>"{this.context.bio}"</p>
									<img src={`${secure ? "https" : "http"}://${host}:${port}/images/profilePicture?uuid=${this.context.picture}&imageCount=${this.context.pictureCount}`} className={"profilePic"} alt="Profile Picture" />
									{!!(this.context.groups && this.context.groups.length > 0) && (<div style={{marginTop: "10px"}}>You're in {this.context.groups.length} group{this.context.groups.length > 1 && "s"} 
                                            <ul style={{marginTop: "0"}}>
                                            {this.context.groups.map((e) => {
                                                return <li key={e}><Link to={"/groups/" + e}>{e}</Link></li>;
                                            })}
                                            </ul>
                                    </div>)}
							</div>
						:
							<div id="profileSplit">
								<div className="uni">
									
									<p>Change profile picture <input id="profilePic" type="file" accept="image/png" name={"profilePic"} onChange={(event) => {
										var self = this;
										readFileDataAsBase64(event).then((file) => {
											self.setState({
												image: file.split(",")[1]
												
											})
										}).catch((err) => {
											console.log(err)
										})
									}}/></p>
									<p>Bio <input type={"text"} maxLength="280" name="bio" id="bio" value={this.state.bio} onChange={this.handleInputChange} /></p>
									<p>Privacy settings:</p>
									<ul style={{listStyle: "none"}}>
										<li><input type="radio" id={"ps1"} readOnly={true} value={1} onClick={this.handleInputChange} checked={this.state.privacySettings == 1} name="privacySettings"/> <label htmlFor={"ps1"}>Anyone can see my profile</label></li>
										<li><input type="radio" id={"ps2"} readOnly={true} value={2} onClick={this.handleInputChange} checked={this.state.privacySettings == 2} name="privacySettings"/> <label htmlFor={"ps2"}>Only my friends can see my profile</label></li>
										<li><input type="radio" id={"ps3"} readOnly={true} value={3} onClick={this.handleInputChange} checked={this.state.privacySettings == 3} name="privacySettings"/> <label htmlFor={"ps3"}>No one can see my profile</label></li>
									</ul>
									<p><button onClick={this.makeRequest}>Update</button></p>
									<p>{this.state.error}</p>
								</div>
							</div>
						}
						
						
						
                    </MainPage>
					
                </div>
            </div>
        )
	}
}

export default Profile;