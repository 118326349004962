import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import axios from "axios";

import {connection, retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host, makeReadableDate, orderExercise, getReadableTime} from "./main.js";

import ReactDOMServer from 'react-dom/server';

import {
	Link
} from 'react-router-dom';


class Total extends React.Component {
	constructor(props) {
		super(props);

        this.titles = ["Get Total With Weeks, Days, and Regimens", "Get Total With 2 Dates", "Get Total of Entire Regimens"];

		this.state = {
			date1: "2021-01-04",
            date2: "2021-03-28",
            exercises: [],
            activeArea: 2,
            regimen: "*",
            Cregimen: "2021q1",
            message: "No request made",
            date1week: 1,
            C1daycompare: true,
            C2weekcompare: true,
            C2daycompare: true,
            temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=error`,
            C1day: 1,
            C2day: 1,
            C1week: 1,
            C2week: 1,
            hideImage: true,
            personalised: false,
            liveUpdates: true,
            receivedUpdate: false
            
		};
	
        this.handleInputChange = this.handleInputChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.changeActiveArea = this.changeActiveArea.bind(this);
        
        this.getDisabled = this.getDisabled.bind(this);
        this.manualIncrease = this.manualIncrease.bind(this);
        this.makeImageRequest = this.makeImageRequest.bind(this);

        var disabledvalues = {};

	}

    handleWebSocket(message, type) {
        if (type == "myExercise") {
            this.setState({
                receivedUpdate: true
            })
            if (this.state.personalised && this.state.liveUpdates) {
                this.makeRequest();
            }
        }
    }

    componentDidMount() {
        this.makeRequest();
        this.disabledvalues = this.getDisabled();

        connection.on("myExercise", (message) => this.handleWebSocket(message, "myExercise"));
    }

    componentWillUnmount() {
        

        this.handleWebSocket = (message, type) => {};
    }


	handleInputChange(event) {
		console.log(event)
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
            console.log(value)
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}
        console.log(typeof value)

        if (target.id == "C1day" || target.id == "C2day") {
            console.log(this.state[target.id.replace("day", "week")]);
			if (value > 7 ) {
				value = 1;
				this.setState({
					[target.id.replace("day", "week")]: this.state[target.id.replace("day", "week")] + 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			} else if (value < 1) {
				value = 7;
				this.setState({
					[target.id.replace("day", "week")]: this.state[target.id.replace("day", "week")] - 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			}
		}

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value
		}, function () {
            self = this;
            this.disabledvalues = this.getDisabled();
            console.log(this.state);
            this.makeRequest();
        });
	}

    manualIncrease(which, amount) {


		if (!which.includes("week") && (this.state[which] + amount) > 7 ) {
			this.setState({
				[which.replace("day", "week")]: this.state[which.replace("day", "week")] + 1,
                [which]: 1
			});
			// this.setState(prevState => {
			// 	return {week: prevState.week + 1}
			// });
		} else if (!which.includes("week") && (this.state[which] + amount) < 1) {
			this.setState({
				[which.replace("day", "week")]: this.state[which.replace("day", "week")] - 1,
                [which]: 7
			});
			// this.setState(prevState => {
			// 	return {week: prevState.week + 1}
			// });
		} else {
            this.setState({
				[which]: this.state[which] + amount
			});
        }
    }

    getDisabled() {
        var self = this;
        // TRUE = DISABLED
        return {
            get C1day() { 
                return Boolean(!self.state.C1daycompare)
            },
            get C2week() { 
                return Boolean(!self.state.C2weekcompare)
            },
            get C2day() { 
                return Boolean((!self.state.C1daycompare || !self.state.C2daycompare || !self.state.C2weekcompare))
            }
        };
    }

    changeActiveArea(area) {
        console.log(area)
        this.setState({
            activeArea: area
        }, function () {
              this.makeRequest();
        });
    }

    handleClick(event) {
        console.log(event);
        const target = event.target;
        console.log(target);
        if (target.classList.contains("aa1")) {
            this.setState({
                activeArea: 1
            }, function () {
                  this.makeRequest();
            });
        } else if (target.classList.contains("aa2")) {
            this.setState({
                activeArea: 2
            }, function () {
                  this.makeRequest();
            });
        } else if (target.classList.contains("aa3")) {
            this.setState({
                activeArea: 3
            }, function () {
                  this.makeRequest();
            });
        }
    }

    makeRequest() {
        var self = this;

        var data = {};
        var message;

        if (this.state.activeArea == 3) {
            if (this.state.regimen == "*") {
                message = `Throughout all regimens, ${this.state.personalised ? "you" : "we"}'ve done:`;
            } else {
                message = <span>Throughout the <span style={{fontVariant: "small-caps"}}>{this.state.regimen}</span> regimen, {this.state.personalised ? "you" : "we"} did:</span>;
            }
            data = {
                Regimen: this.state.regimen
            }
        }

        if (this.state.activeArea == 1) {
            message = <span>Message</span>;
            
            // if (!this.state.C1daycompare && !this.state.C2weekcompare) {
            //     data = {
            //         Day: [
            //             {
            //                 Week: this.state.C1week
            //             },
            //             {
            //                 Week: this.state.C1week
            //             }
            //         ],
            //         Regimen: this.state.Cregimen
            //     }
            //     message = <span>From <b>Week {data.Day[0].Week}, Day 1</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))}) to <b>Week {data.Day[1].Week}, Day 7</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 7, data.Day[1].Week))}) we did:</span>
            // } else 
            
            if (!this.state.C1daycompare && this.state.C2weekcompare) {
                data = {
                    Day: [
                        {
                            Week: this.state.C1week
                        },
                        {
                            Week: this.state.C2week
                        }
                    ],
                    Regimen: this.state.Cregimen
                }
                message = <span>From <b>Week {data.Day[0].Week}, Day 1</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))}) to <b>Week {data.Day[1].Week}, Day 7</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 7, data.Day[1].Week))}) {this.state.personalised ? "you" : "we"} did:</span>
                this.setState({
                    C1day: 1,
                    C2day: 7
                })
            } else if (this.state.C1daycompare && !this.state.C2weekcompare)  {
                data = {
                    Day: [
                        {
                            Week: 1,
                            Day: 1
                        },
                        {
                            Week: this.state.C1week,
                            Day: this.state.C1day
                        }
                    ],
                    Regimen: this.state.Cregimen
                }
                message = <span>From <b>Week {data.Day[0].Week}, Day 1</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))}) to <b>Week {data.Day[1].Week}, Day {data.Day[1].Day}</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, data.Day[1].Day, data.Day[1].Week))}) {this.state.personalised ? "you" : "we"}  did:</span>
            } else if (this.state.C2weekcompare && this.state.C1daycompare && !this.state.C2daycompare)  {
                data = {
                    Day: [
                        {
                            Week: this.state.C1week,
                            Day: this.state.C1day
                        },
                        {
                            Week: this.state.C2week,
                            Day: 7
                        }
                    ],
                    Regimen: this.state.Cregimen
                }
                message = <span>From <b>Week {data.Day[0].Week}, Day 1</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))}) to <b>Week {data.Day[1].Week}, Day {data.Day[1].Day}</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, data.Day[1].Day, data.Day[1].Week))}) {this.state.personalised ? "you" : "we"}  did:</span>
            } else if (!this.state.C1daycompare && !this.state.C2weekcompare) {
                data = {
                    Day: [
                        {
                            Week: this.state.C1week,
                            Day: 1
                        },
                        {
                            Week: this.state.C1week,
                            Day: 7
                        }
                    ],
                    Regimen: this.state.Cregimen
                }
                message = <span>For <b>Week {data.Day[0].Week}</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))} - {makeReadableDate(dateFromWeekDay(this.state.Cregimen, data.Day[1].Day, data.Day[1].Week))}) {this.state.personalised ? "you" : "we"} did:</span>
            } else {
                data = {
                    Day: [
                        {
                            Week: this.state.C1week,
                            Day: this.state.C1day
                        },
                        {
                            Week: this.state.C2week,
                            Day: this.state.C2day
                        }
                    ],
                    Regimen: this.state.Cregimen
                }
                message = <span>From <b>Week {data.Day[0].Week}, Day 1</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, 1, data.Day[0].Week))}) to <b>Week {data.Day[1].Week}, Day {data.Day[1].Day}</b> ({makeReadableDate(dateFromWeekDay(this.state.Cregimen, data.Day[1].Day, data.Day[1].Week))}) {this.state.personalised ? "you" : "we"} did:</span>
                
            }


            
        }

        

        if (this.state.activeArea == 2) {
            message = `From ${makeReadableDate(this.state.date1)} to ${makeReadableDate(this.state.date2)}, ${this.state.personalised ? "you" : "we"} did:`;
            data = {
                Day: [
                    {Date: this.state.date1},
                    {Date: this.state.date2}
                ]
            }
        }

        data.personalised = this.state.personalised;

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveTotal`, 
        data, 
            {withCredentials: true}
        )
        .then(function (response) {
            
            console.log("ok")

            response.data.data = response.data.data.filter(function( o ) {
                return (o.amount != 0 || o.second != 0);
            });

            var newExercises = [];

            // response.data;

            console.log(response.data.data)

            response.data.data = orderExercise(response.data.data);

            response.data.data.map(a => {
                if (a.amount == 0) {
                    return (a.amount = 2)   
                }   
            });

            console.log(response.data.data);

            self.setState({
                exercises: response.data.data,
                message: message,
                hideImage: true,
                receivedUpdate: false
            }, () => {

                console.log(self.state);
            });
        })
        .catch(function (error) {
        })
    }

    makeImageRequest() {
        this.setState({
            hideImage: false,
            temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=rendering`
        });

        var self = this;
        var regimenName;
        var dayInfo;
        var info;
        if (this.state.activeArea == 1) {
            regimenName = this.state.Cregimen;
            dayInfo = "this area needs text to not look bare"
            info = "So does this...";
        } else if (this.state.activeArea == 2) {
            dayInfo = `${makeReadableDate(this.state.date1)} - ${makeReadableDate(this.state.date2)}`;
            info = self.state.message;
        }else if (this.state.activeArea == 3) {
            regimenName = (this.state.regimen == "*" ? "all regimens" : this.state.regimen);
            dayInfo = `${makeReadableDate(dateFromWeekDay("2020q4", 1, 1))} - ${makeReadableDate(dateFromWeekDay("2021q1", 7, 12))}`;
            info = self.state.message;
        } 
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/imageTest`, 
        {
            regimenName,
            exercises: self.state.exercises,
            ImageType: "totals",
            TotalImage: true,
            WeekText: dayInfo,
            info
        }, 
            {
                withCredentials: true, 
                responseType: 'arraybuffer'
            }
        )
        .then(function (response) {
            
            // not entirely happy with this part but it has to do

            var temp = "data:image/png;base64, " + (Buffer.from(response.data, 'binary').toString('base64'))
            self.setState({
                temp,
            })
             
        })
        .catch(function (error) {
            self.setState({
                temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=error`,
            })
        
            if( error.response ){
                console.log(error.response.data);  
            }
        })
    }

	render() {
        return (
            <div>
                <div className="App" 
				    style={{
					    WebkitUserSelect: "none",
					    MozUserSelect: "none",
					    msUserSelect: "none",
					    userSelect: "none",
				    }}
			    >
				    <MainPage title="Totals">
                        <p>Give two dates or days and see how much exercise {this.state.personalised ? "you" : "we"} did in that time period. Alternatively, give an entire regimen or find out how much exercise {this.state.personalised ? "you" : "we"}'ve done throughout all regimens. <b>This part is really cool but doesn't work great on mobile at the moment so please use a computer to test it out fully</b></p>
                        <ul style={{listStyle: "none", padding: "0", minHeight: "45px"}}>
                            <li><input type={"checkbox"} checked={this.state.personalised} onChange={this.handleInputChange} id={"personalised"} name={"personalised"} /><label htmlFor={"personalised"}> See your personal exercise {(!this.state.personalised) ? <b>(Seeing base exercise)</b> : <b>(Seeing personal exercise)</b>}</label></li>
                            <li style={{opacity: this.state.personalised ? "1" : "0.2"}}><input type={"checkbox"} disabled={!this.state.personalised} checked={this.state.liveUpdates} onChange={this.handleInputChange} id={"liveUpdates"} name={"liveUpdates"} /><label htmlFor={"liveUpdates"}> Live updates</label> {!this.state.liveUpdates ? <span>
                                <button onClick={this.makeRequest} style={this.state.receivedUpdate ? {fontWeight: "bold"} : {}} title={this.state.receivedUpdate ? "Updates received, click to update" : "You're viewing the latest data"}>
                                    Update
                                </button>
                            </span> : ""}</li>
                        </ul>
                        <div id={"methods"}>
                            
                            <div id="table">
                                <div className="tr titles">
                                    <div className={`methodSection td aa1 ${(this.state.activeArea == 1 ? "selected" : "")}`} onClick={() => this.changeActiveArea(1)}>{this.titles[0]}</div>
                                    <div className={`methodSection td aa2 ${(this.state.activeArea == 2 ? "selected" : "")}`} onClick={() => this.changeActiveArea(2)}>{this.titles[1]}</div>
                                    <div className={`methodSection td aa3 ${(this.state.activeArea == 3 ? "selected" : "")}`} onClick={() => this.changeActiveArea(3)}>{this.titles[2]}</div>
                                </div>
                                <div className="tr">
                                    {/* this part is unfinished */}
                                    <div className={`methodSection td aa1 ${(this.state.activeArea == 1 ? "selected" : "")}`} onClick={() => this.changeActiveArea(1)}>
                                        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
                                            <li className="hide">{this.titles[0]}</li>
                                            <li>Regimen   
                                                <select disabled={(this.state.activeArea != 1)} id="Cregimen" value={this.state.Cregimen} name="Cregimen" style={{width: "100%"}} onChange={this.handleInputChange}>
        	                    	    	        <option value="2021q1">2021q1</option>
        	                    	    	        <option value="2020q4">2020q4</option>
        	                    	    	    </select>
                                            </li>
                                            <li>
                                                Week <br/>

                                                <input style={{width: "30%"}} disabled={(this.state.activeArea != 1)} type={"number"} value={this.state.C1week} id="C1week" name="C1week" onChange={this.handleInputChange}/>

                                                <button disabled={(this.state.activeArea != 1)} onClick={() => this.manualIncrease("C1week", 1)}>⬆️</button>
                                                <button disabled={(this.state.activeArea != 1)} onClick={() => this.manualIncrease("C1week", -1)}>⬇️</button>
                                            </li>
                                            
                                            <li>
                                                Day 
                                                <input type={"checkbox"} checked={this.state.C1daycompare} name="C1daycompare" id="C1daycompare" disabled={(this.state.activeArea != 1)} onChange={this.handleInputChange}/> <br/>

                                                <input style={{width: "30%"}} type={"number"} value={this.state.C1day} id="C1day"  name="C1day" onChange={this.handleInputChange} disabled={(this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C1day : true))}/>

                                                <button disabled={(this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C1day : true))} onClick={() => this.manualIncrease("C1day", 1)}>⬆️</button>
                                                <button disabled={(this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C1day : true))} onClick={() => this.manualIncrease("C1day", -1)}>⬇️</button>
                                            </li>

                                            <li><i style={{textDecoration: (this.disabledvalues ? this.disabledvalues.C2week : false) ? "line-through" : ""}}>Comparing with</i></li>
                                            
                                            <li style={{textDecoration: (this.disabledvalues ? this.disabledvalues.C2week : false) ? "line-through" : ""}}>
                                                <input type={"checkbox"} checked={this.state.C2weekcompare} name="C2weekcompare" id="C2weekcompare" disabled={(this.state.activeArea != 1)} onChange={this.handleInputChange}/> Week <br/>

                                                <input style={{width: "30%"}} type={"number"} value={this.state.C2week} id="C2week" name="C2week" onChange={this.handleInputChange} disabled={this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2week : true)}/>

                                                <button disabled={(this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2week : true))} onClick={() => this.manualIncrease("C2week", 1)}>⬆️</button>
                                                <button disabled={(this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2week : true))} onClick={() => this.manualIncrease("C2week", -1)}>⬇️</button>
                                            </li>

                                            <li style={{textDecoration: (this.disabledvalues ? this.disabledvalues.C2week : false) ? "line-through" : ""}}>
                                                <input type={"checkbox"} checked={this.state.C2daycompare} name="C2daycompare" id="C2daycompare" onChange={this.handleInputChange} disabled={this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2week || this.disabledvalues.C1day : true)} /> 
                                                
                                                Day <br/>
                                                
                                                <input style={{width: "30%"}} type={"number"} value={this.state.C2day} id="C2day"  name="C2day" onChange={this.handleInputChange} disabled={this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2day : true)} />

                                                <button disabled={this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2day : true)} onClick={() => this.manualIncrease("C2day", 1)}>⬆️</button>
                                                <button disabled={this.state.activeArea != 1 || (this.disabledvalues ? this.disabledvalues.C2day : true)} onClick={() => this.manualIncrease("C2day", -1)}>⬇️</button>
                                            </li>

                                            <li style={{display: ((this.state.Cregimen == "2020q4" && this.state.C1week > 9) || (this.state.Cregimen == "2021q1" && this.state.C1week > 12) || (this.state.Cregimen == "2020q4" && this.state.C2week > 9) || (this.state.Cregimen == "2021q1" && this.state.C2week > 12)) ? "block" : "none"}}>
                                                <b>There are only {this.state.Cregimen == "2020q4" ? 9 : 12} weeks in <span style={{fontVariant: "small-caps"}}>{this.state.Cregimen}</span>.</b>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`methodSection td aa2 ${(this.state.activeArea == 2 ? "selected" : "")}`} onClick={() => {this.changeActiveArea(2)}}>
                                        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
                                            <li className="hide">{this.titles[1]}</li>
                                            <li>Date 1 <br/><input style={{width: "100%"}} disabled={(this.state.activeArea != 2)} type={"date"} value={this.state.date1} id="date1"    name="date1" onChange={this.handleInputChange}/></li>
                                            <li>Date 2 <br/><input style={{width: "100%"}} disabled={(this.state.activeArea != 2)} type={"date"} value={this.state.date2} id="date2"    name="date2" className={"aa2"} onChange={this.handleInputChange}/></li>
                                        </ul>
                                    </div>
                                    <div className={`methodSection td aa3 ${(this.state.activeArea == 3 ? "selected" : "")}`} onClick={() => {this.changeActiveArea(3)}}>
                                        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
                                            <li className="hide">{this.titles[2]}</li>
                                            <li>Regimen   
                                                <select disabled={(this.state.activeArea != 3)} id="regimen" value={this.state.regimen} name="regimen" className={"aa3"}  style={{width: "100%"}} onChange={this.handleInputChange}>
        	                    	    	        <option value="2021q1">2021q1</option>
        	                    	    	        <option value="2020q4">2020q4</option>
                                                    <option value="*">All</option>
        	                    	    	    </select>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                        <div id="dayMessage"
						style={{
							WebkitUserSelect: "text",
							MozUserSelect: "text",
							msUserSelect: "text",
							userSelect: "text",
						}}>
                            <span>
							    {this.state.message}
						    </span>
                            <ul id="exercises">
       					    	{this.state.exercises.map((temp) => {
						    		if (temp.amount && !temp.second) {
						    			return (<li key={temp.type}><b>{temp.amount} {returnPlural(temp.amount, allExercises[temp.type])}</b> {temp.per ? `(${temp.amountround} per ${temp.per})` : ""}</li>);         
						    		} else if (temp.second) {
						    			return (<li key={temp.type}><b>{returnPlural(temp.amount, allExercises[temp.type])} for {getReadableTime(temp.second)}</b></li>);
						    		} else {
						    			return (<li key={temp.type}><b>{temp.type}</b></li>)
						    		}
        				    	})}
      					    </ul>
                        </div>

                        <p>
                            <button onClick={() => {
                                
                                this.makeImageRequest();
                            }}>
                                Produce an Image
                            </button>
                        </p>
                        

                        {(!this.state.hideImage ? <span>
                            <img src={this.state.temp} className={"regimenImage"} alt="Image" />
                        </span> : "")}

                    </MainPage>
                </div>
            </div>
        )
	}
}

export { 
	Total
};

{/* <div className={`method ${(this.state.activeArea == 1 ? "selectedMethod" : "")}`} onClick={() => {
    this.setState({
        activeArea: 1
    }, () => {
        this.makeRequest();
    })
}}>
    <div className="methodTitle">
        With Weeks, Days, and Regimens
    </div>
    <div className="methodContent">
        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
            <li>Week <br/><input style={{width: "30%"}} disabled={(this.state.activeArea != 1)} type={"number"} value={this.state.date1week} id="date1week" name="date1week" onChange={this.handleInputChange}/><button>⬆️</button><button>⬇️</button></li>
            <li>Day <br/><input style={{width: "30%"}} disabled={(this.state.activeArea != 1)} type={"number"} value={this.state.date1week} id="date1week" name="date1week" onChange={this.handleInputChange}/><button>⬆️</button><button>⬇️</button></li>
        
        </ul>
    </div>
</div>

<div className={`method ${(this.state.activeArea == 2 ? "selectedMethod" : "")}`} onClick={() => {
    this.setState({
        activeArea: 2
    }, () => {
        this.makeRequest();
    })
}}>
    <div className="methodTitle">
        With Dates
    </div>
    <div className="methodContent">
        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
            <li>Date 1 <br/><input style={{width: "100%"}} disabled={(this.state.activeArea != 2)} type={"date"} value={this.state.date1} id="date1" name="date1" onChange={this.handleInputChange}/></li>
            <li>Date 2 <br/><input style={{width: "100%"}} disabled={(this.state.activeArea != 2)} type={"date"} value={this.state.date2} id="date2" name="date2" onChange={this.handleInputChange}/></li>
        </ul>
    </div>
</div>

<div className={`method ${(this.state.activeArea == 3 ? "selectedMethod" : "")}`} onClick={() => {
    this.setState({
        activeArea: 3
    }, () => {
        this.makeRequest();
    })
}}>
    <div className="methodTitle">
        Entire Regimens
    </div>
    <div className="methodContent">
        <ul style={{listStyle: "none", padding: 0, margin: "0"}}>
            <li>Regimen   
                <select disabled={(this.state.activeArea != 3)} id="regimen" value={this.state.regimen} name="regimen"  style={{width: "100%"}} onChange={this.handleInputChange}>
                    <option value="2021q1">2021q1</option>
                    <option value="2020q4">2020q4</option>
                    <option value="*">All</option>
                </select>
            </li>
        </ul>
    </div>
</div> */}