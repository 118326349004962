import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import axios from "axios";

import {connection, port, secure, host, makeReadableDate, user, getReadableTime, dateFromWeekDay, allExercises, returnPlural } from "./main.js";

import { DateTime } from "luxon";


import RecordExercise from "./Reusable/RecordExercise.js";


import userDefault from "./sourceImages/userDefault.svg"

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";

class SocialExercise extends React.Component {

	
    static contextType = UserContext;

	constructor(props) {
		super(props);
        this.state = {
            week: 1,
            day: 1,
            date: "2021-01-04",
			activeFriend: "",
            activeDay: {},
            modelExercises: {},
            modelDate: "",
            activeDate: false,
            skipSeven: true,
            regimen: "2021q1",
            madeRequest: false,
            responseMessage: <p>Press View above</p>,
            challenge: true
        }

		this.makeRequest = this.makeRequest.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlankInput = this.handleBlankInput.bind(this);
        this.handleDaySpecific = this.handleDaySpecific.bind(this);
	}

    handleDaySpecific(name, value) {

        if (name == "day") {
            if (value > (this.state.skipSeven ? 6 : 7) ) {
                value = 1;
                this.setState({
                    week: this.state.week + 1
                });
            } else if (value < 1) {
                value = this.state.skipSeven ? 6 : 7;
                this.setState({
                    week: this.state.week - 1
                });
            }
        }
		
		
        var self = this; 
		this.setState({
			[name]: value,
		  //   activeDate: true,
		}, () => {
            self.setState({
                date: !self.state.activeDate ? dateFromWeekDay(self.state.regimen, self.state.day, self.state.week) : self.state.date
            }, () => {
                self.makeRequest();
            })
		});
	} 

    handleBlankInput() {
        if (!this.state.activeFriend && this.context.friends.length) {
            var self = this;
            this.setState({
                activeFriend: this.context.friends[0].friend.name
            }, () => {
                if (!self.state.madeRequest && self.context.friends.length) {
                    self.makeRequest()
                    self.setState({
                        madeRequest: true
                    })
                }
            })
        }
    }

    handleInputChange(event, handlingDayWeek) {
        
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;

        if (target.id == "day" || handlingDayWeek) {
			if (value > (this.state.skipSeven ? 6 : 7) ) {
				value = 1;
				this.setState({
					week: this.state.week + 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			} else if (value < 1) {
				value = this.state.skipSeven ? 6 : 7;
				this.setState({
					week: this.state.week - 1
				});
				// this.setState(prevState => {
				// 	return {week: prevState.week + 1}
				// });
			}
		}
		
    	this.setState({
		  [name]: value,
          
		}, function () {
            var self = this;

            self.setState({
                date: !self.state.activeDate ? dateFromWeekDay(self.state.regimen, self.state.day, self.state.week) : self.state.date
            }, () => {
                self.makeRequest();
            })

            

            
        });
	}

    handleWebSocket(message, type) {
        if (type == "friendExercise") {
            console.log(message)
            if (typeof message == "object" && this.state.date == message.date && this.state.activeFriend == message.user) {
                this.setState({
                    activeDay: message.response.fullDay
                });

                var self = this;
                if (message.date != this.state.modelDate) {
                    axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveDay`, 
        	        {
                        date: this.state.activeDate ? this.state.date : "",
			        	week: this.state.week,
			        	day: this.state.day,
			        	regimen: this.state.regimen
			        }, 
        	            {
        	                withCredentials: true
        	            }
        	        )
        	        .then (function (response) {
                        if (response.status == 200) {

                            var modelExercises = {}
			                response.data.fullDay.exercises.forEach(e => {
			                	modelExercises[e.type] = {
			                		type: e.type,
			                		amount: e.amount,
			                		amountround: e.amountround > 0 ? e.amountround : 1,
			                		per: e.per,
			                		rounds: e.rounds,
			                		second: e.second
			                	};
			                });


                            self.setState({
                                modelExercises,
                                modelDate: response.data.fullDay.date
                            })
                        } else {
                            throw "Not found";
                        }
                    }).catch((err) => {
                        console.error(err)
                        self.setState({
                            
                            modelExercises: []
                        })
                    })
                }
            }  
            
        }
    }

    componentWillUnmount() {
        connection.invoke("FriendExercise", {
            action: "exit"
        }).catch(function (err) {
            return console.error(err.toString());
        });

        this.handleWebSocket = (message, type) => {};
    }

    componentDidUpdate() {
        
        this.handleBlankInput();
        
    }

    componentDidMount() {

        this.handleBlankInput();

        connection.on("friendExercise", (username) => this.handleWebSocket(username, "friendExercise"));

        
    }

    makeRequest() {
        this.handleBlankInput();
        var self = this;

        connection.invoke("FriendExercise", {
            user: this.state.activeFriend,
            date: this.state.date
        }).catch(function (err) {
            return console.error(err.toString());
        });

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageExercise`, 
        {
            date: this.state.activeDate ? this.state.date : "",
            week: this.state.week,
            day: this.state.day,
            regimen: this.state.regimen,
            info: "check",
            name: this.state.activeFriend
        }, 
            {
                withCredentials: true
            }
        )
        .then (function (response) {

            console.log(response)

            if (response.status == 204) {
                throw "Dayn't started"
            }
			
			if (response.data) {
				self.setState({
					activeDay: response.data
				});

        	    axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveDay`, 
        	    {
                    date: self.state.activeDate ? self.state.date : "",
			    	week: self.state.week,
			    	day: self.state.day,
			    	regimen: self.state.regimen
			    }, 
        	        {
        	            withCredentials: true
        	        }
        	    )
        	    .then (function (response) {
                    if (response.status == 200) {

                        var modelExercises = {}
			            response.data.fullDay.exercises.forEach(e => {
			            	modelExercises[e.type] = {
			            		type: e.type,
			            		amount: e.amount,
			            		amountround: e.amountround > 0 ? e.amountround : 1,
			            		per: e.per,
			            		rounds: e.rounds,
			            		second: e.second
			            	};
			            });


                        self.setState({
                            modelExercises,
                            modelDate: response.data.fullDay.date
                        })
                    } else {
                        throw "Not found";
                    }
                }).catch(() => {
                    self.setState({
                        modelExercises: []
                    })
                })

			}
        })
        .catch(function (error) {
            self.setState({
                responseMessage: <p>This user likely hasn't started this day</p>,
                activeDay: {}
            });
        })
    }

	render() {
        
        return (
            <div>
                <div className="App"
				    style={{
					    WebkitUserSelect: "none",
					    MozUserSelect: "none",
					    msUserSelect: "none",
					    userSelect: "none",
				    }}
                    className="socialExercise"
			    >
				    <MainPage title="Social Exercise">
                        <p>View each day of exercise your friends have done</p>
                        <ul style={{listStyle: "none", padding: "0"}}>
                            <li><input type={"checkbox"} checked={this.state.challenge} onChange={this.handleInputChange} id={"challenge"} name={"challenge"} /><label htmlFor={"challenge"}> Challenge</label></li>
                        </ul>
                        {this.context.friends.length && this.context.friends.filter(e => e.friend.sharing).length > 0 ?
                            <span>
                                <table id="division">
					              	<thead>
					            	  	<tr>
					                		<th>Retrieve with Week, Day, and Regimen</th>
					                		<th>Retrieve with Date</th> 
					              		</tr>
					            	</thead>
					              	<tbody>
					            	  	<tr>
					            			<td onClick={() => {
					            					this.setState({
					            						activeDate: false
					            					});
					            				}}>
					            				<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	                        				<li key="weeks">
					            							Week: <input type="number" ref={this.week} value={this.state.week} name="week" style={{width: "100%", boxSizing: 'border-box'}} id="week" disabled={this.state.activeDate} onChange={this.handleInputChange} />
                                                            <button onClick={() => this.handleDaySpecific("week", this.state.week + 1)}>⬆️</button>
                                                            <button onClick={() => this.handleDaySpecific("week", this.state.week - 1)}>⬇️</button>
        	                        				</li>
        	                        				<li key="days">
                                            
                                            
        	                        				    	Day: <input type="number" ref={this.day} value={this.state.day} name="day"  style={{width: "100%", boxSizing: 'border-box'}} id="day" disabled={this.state.activeDate} onChange={this.handleInputChange} />
                                                            <button onClick={() => this.handleDaySpecific("day", this.state.day + 1)}>⬆️</button>
                                                            <button onClick={() => this.handleDaySpecific("day", this.state.day - 1)}>⬇️</button>
					            					</li>
        	                        				<li key="regimens">
        	                        				    <select id="regimen" value={this.state.regimen} name="regimen" style={{width: "100%"}} disabled={this.state.activeDate} onChange={this.handleInputChange}>
        	                        				        <option value="2021q1">2021q1: "RESURRECTION"</option>
        	                        				        <option value="2020q4">2020q4: "INCARNATION"</option>
        	                        				    </select>
        	                        				</li>
        	                    				</div></ul>
					            			</td>
					                		<td onClick={() => {
					            					this.setState({
					            						activeDate: true
					            					});
					            				}}>
					            				<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	                        				<li>Date: <input type="date" name="date" id="date" value={this.state.date} disabled={!this.state.activeDate} onChange={this.handleInputChange}/></li>
					            					<li><button disabled={!this.state.activeDate} onClick={() => {
                                                        this.handleDaySpecific("date", (new Date()).toISOString().substring(0, 10))
                                                    
					            					}}>Today</button></li>
					            				</div></ul>
					            			</td>
					              		</tr>
					              	</tbody>
					            </table>
                                <div style={{width: "100%"}}>
                                    <select style={{width: "80%", display: "inline", boxSizing: "border-box", marginTop: "20px", height: "50px"}} id="activeFriend" name="activeFriend" value={this.state.activeFriend} disabled={!this.context.friends.length} onChange={this.handleInputChange}>
						                	{
						                		this.context.friends ? 
						                		this.context.friends.map((r, i) => {
                                                if (r.friend.sharing) {
                                                    return <option key={i} value={r.friend.name}>{r.friend.name}</option>;
                                                }
                                            
						                		}) : ""
						                	}
						                </select><button style={{width: "20%", display: "inline", boxSizing: "border-box", marginTop: "20px", height: "50px"}} onClick={this.makeRequest}>View</button>
                                </div>
                                
                                {this.state.challenge ? 
                                    <span>
                                        <RecordExercise 
					                        regimen={this.state.regimen} day={this.state.day} week={this.state.week}
					                    />
                                    </span>
                                : ""}

                                {this.state.activeDay.exercises ? 
                                    <span>
                                        <p style={{marginBottom: "0"}}><b>Week {this.state.activeDay.week}; Day {this.state.activeDay.day}</b> - {makeReadableDate(this.state.activeDay.date)} {this.state.activeDay.complete ? <b>(Complete ✓)</b> : "(Incomplete)"}</p>
                                        <span><b>@{this.state.activeFriend}</b> has done:</span>
                                        <ul style={{margin: "0", paddingTop: "0", paddingLeft: "40px"}} className={"myExerciseList"}>
						                    	{(this.state.activeDay.exercises.map((r) => {

                                                if (this.state.modelExercises[r.type]) {
                                                    var modelExercise = this.state.modelExercises[r.type];
                                                    var possibleTime = "";
                                                    var time = "";
                                                    var bold = {};
                                                
                                                    if (modelExercise.second) {
                                                        possibleTime = `; ${getReadableTime(((modelExercise.amount * modelExercise.second) - r.second), true)} left`
                                                        time = ` of ${getReadableTime(modelExercise.second)} `;
                                                    }
                                                
                                                    var remainder = `(${modelExercise.amount - r.amount} to go${possibleTime})`;
                                                
                                                    if (r.amount < modelExercise.amount) {
                                                        bold = {
                                                            fontWeight: "bold"
                                                        }
                                                    } else {
                                                        bold = {
                                                            // fontStyle: "italic"
                                                        }
                                                        remainder = "(all done) ✓"
                                                    }
                                                
                                                    return <li onClick={() => {
                                                        this.setState({
                                                            activeExercise: r.type
                                                        })
                                                    }} key={r.type} style={bold}>{r.amount} {returnPlural(r.amount, allExercises[r.type])}{time} {remainder} </li>
                                                }

						                    	}))}
						                    </ul>
                                    </span>
                                : this.state.responseMessage}
                            </span>
                            :
                            <b>Add some friends first</b>
                        }
                        
                    </MainPage>
					
                </div>
            </div>
        )
	}
}

export default SocialExercise;