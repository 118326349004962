import React, { useRef, useEffect } from 'react';
import MainPage from './MainPage';

import axios from "axios";

import {connection, retrieveDay, allExercises, returnPlural, dateFromWeekDay, port, secure, host, makeReadableDate, orderExercise} from "./main.js";

import {
	Link
} from 'react-router-dom';

import { DateTime } from "luxon";

	

class Imaging extends React.Component {
	constructor(props) {
		super(props);


        var currentDate = DateTime.fromJSDate(new Date());

        // luxon.DateTime.now({zone: 'Europe/London'}).startOf('week').toISO()
        // luxon.DateTime.now().toFormat("EEEE dd MMM");

        
        this.state = {
            fancyDate: currentDate.toISODate(),
            exercises: [
                {
                    "type": "lunges",
                    "amount": 50,
                    "second": 0,
                    "rounds": 1,
                    "amountround": 25,
                    "per": "leg"
                }
            ],
            message: <p><b>Press submit above or below.</b> The data you enter to create your image may be saved on the server.</p>,
            week: 0,
            day: parseInt(currentDate.toFormat("E")),
            caption: "example text",
            weekDates: `${currentDate.startOf('week').toFormat("dd MMM")} - ${currentDate.endOf('week').toFormat("dd MMM")}`,
            dayText: currentDate.toFormat("EEEE dd MMM"),
            temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=error`,
            images: false,
            regimenname: "resurrection",
            colourone: "#ad005a",
            colourtwo: "black"
        }

        this.keyCount = 0;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
	}

    handleDate(event, alt) {
        

		var value;
    	var name;

        if (!alt) {
            var target = event.target;
            value = target.value;  
            name = target.name; 
        } else {
            value = alt.value;  
            name = alt.name; 
        }

        var currentDate = DateTime.fromISO(value);

        
        
        if (alt) {
            if (alt.command == "add") {
                currentDate = currentDate.plus({ days: 1 } )
            } else if (alt.command == "subtract") {
                currentDate = currentDate.plus({ days: -1 } )
            }
        }

    	this.setState({
		  [name]: alt ? currentDate.toISODate() : value,
          day: parseInt(currentDate.toFormat("E")),
          caption: "example text",
          weekDates: `${currentDate.startOf('week').toFormat("dd MMM")} - ${currentDate.endOf('week').toFormat("dd MMM")}`,
          dayText: currentDate.toFormat("EEEE dd MMM"),
		});
    }

    componentDidMount() {
        this.images = JSON.parse(localStorage.getItem('storedImages'));
        if (typeof this.images != "object") {
            localStorage.setItem('storedImages', JSON.stringify([]));
        } else {
            this.setState({
                images: true
            })
        }
    }

    handleChange(event) {
        var index = event.target.attributes.order.value;
        console.log(event.target.attributes.order.value);
        var temp = this.state.exercises;
        temp[index][event.target.name] = event.target.value;

        if (event.target.value == "lunges") {
            temp[index]["per"] = "leg";
        }

        this.setState({
            exercises: temp
        }, () => {
            console.log(this.state);
        })



    }

	handleInputChange(event) {
		console.log(event)
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
            console.log(value)
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}
        console.log(typeof value)

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value
		}, function () {
            self = this;
            // console.log(this.state);
            // this.makeRequest();
        });
	}

    makeRequest() {

        

        this.setState({
            temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=rendering`
        });

        var self = this;
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/imageTest`, 
        {
            exercises: self.state.exercises,
            regimenname: `"${self.state.regimenname}"`,
            CaptionText: self.state.caption,
            WeekNumber: "WEEK " + self.state.week,
            WeekText: self.state.weekDates,
            DayNumber: "DAY " + self.state.day,
            DayText: self.state.dayText,
            DayStamp: `Week ${self.state.week}, Day ${self.state.day} ${(self.state.day > 0 && self.state.day < 8) ? "(" + ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'][self.state.day-1].substring(0, 3) + ")" : ""}`,
            Info: `FOR DAY ${self.state.day}${(self.state.day > 0 && self.state.day < 8) ? ", " + ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'][self.state.day-1].toUpperCase() : ""}`,
            ColourOne: self.state.colourone,
            ColourTwo: self.state.colourtwo,
            Date: DateTime.fromISO(self.state.fancyDate).toFormat("dd MMM yyyy")
        }, 
            {
                withCredentials: true, 
                responseType: 'arraybuffer'
            }
        )
        .then(function (response) {
            
            

            var temp = "data:image/png;base64, " + (Buffer.from(response.data, 'binary').toString('base64'))
            self.setState({
                temp,
                message: <p>This image itself will not be stored on the server, but the data you have entered to create the image may be.</p>
            })

            // self.images.push({
            //     info: `"${self.state.caption}" ${new Date().toTimeString()}`,
            //     image: temp
            // })
            // localStorage.setItem('storedImages', JSON.stringify(self.images));
            // self.setState({
            //     images: true
            // })
             
        })
        .catch(function (error) {
            self.setState({
                temp: `${secure ? "https" : "http"}://${host}:${port}/images/retrieveImage?regimen=error`,
                message: <p>An error occured</p>
            })
        
            if( error.response ){
                console.log(error.response.data);  
                // self.setState({
                //     response: error.response.data.info
                // })
            }
        })
    }

	render() {
        return (
            <div>
                <div className="App" 
				    style={{
					    // WebkitUserSelect: "none",
					    // MozUserSelect: "none",
					    // msUserSelect: "none",
					    // userSelect: "none",
				    }}
			    >
				    <MainPage title="Create an Image">
                        <p>
                            Either choose a date below or fill in the values under. You can choose the exercises, scroll to the bottom of the page. 
                        </p>
                        <div style={{width: "50%", margin: "0 auto"}}>
                            <input type={"date"} id={"fancyDate"} name={"fancyDate"} value={this.state.fancyDate} onChange={this.handleDate} style={{width: "100%", boxSizing: "border-box"}}/>
                            <div><button style={{width: "50%"}} onClick={() => {
                                this.handleDate(undefined, {
                                    name: "fancyDate",
                                    value: this.state.fancyDate,
                                    command: "add"
                                })
                            }}>⬆️</button><button style={{width: "50%"}} onClick={() => {
                                this.handleDate(undefined, {
                                    name: "fancyDate",
                                    value: this.state.fancyDate,
                                    command: "subtract"
                                })
                            }}>⬇️</button></div>
                        </div>

                        <table style={{
                            margin: "0 auto",
                            marginTop: "20px"
                        }}>
                            <tbody>
                                <tr>
                                    <td ><b>Caption</b></td>
                                    <td><input type="text" value={this.state.caption} name={"caption"} id="caption" onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td ><b>Week</b></td>
                                    <td><input type="number" value={this.state.week} name={"week"} id="week" onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td >Day</td>
                                    <td><input type="number" value={this.state.day} name={"day"} id="day" onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td >Day Info</td>
                                    <td><input type="text" value={this.state.dayText} name={"dayText"} id="dayText" onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td >Week Info</td>
                                    <td><input type="text" value={this.state.weekDates} name={"weekDates"} id="weekDates" onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td><b>Colour #1</b></td>
                                    <td><input type="color" id="colourone" name="colourone" value={this.state.colourone} style={{width: "100%", boxSizing: "border-box"}} onChange={this.handleInputChange}/></td>
                                </tr>
                                <tr>
                                    <td><b>Colour #2</b></td>
                                    <td><input type="color" id="colourtwo" name="colourtwo" value={this.state.colourtwo} style={{width: "100%", boxSizing: "border-box"}} onChange={this.handleInputChange}/></td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td><input type={"submit"} name={"submit"} id={"submit"} onClick={this.makeRequest}/></td>
                                    <td>
                                        <select id="regimenname" value={this.state.regimenname} name="regimenname" style={{width: "100%"}} onChange={this.handleInputChange}>
        	                    	    	<option value="resurrection">"resurrection"</option>
        	                    	    	<option value="incarnation">"incarnation"</option>
        	                    	    </select>
                                    </td>
                                </tr>
                            </tfoot> 
                        </table>
                        <img src={this.state.temp} className={"regimenImage"} alt="Image" />
                        {this.state.message}
                        <p><input type={"submit"} name={"submit"} id={"submit2"} onClick={this.makeRequest}/></p>
                    </MainPage>


                    <table id="exerciseEditor" className={"ownExercise"}
				    	style={{
				    		WebkitUserSelect: "text",
				    		MozUserSelect: "text",
				    		msUserSelect: "text",
				    		userSelect: "text",
				    	}}
				    >
    			        <thead>
    			            <tr>
    			                <th>Exercise <button onClick={() => {
                                    this.keyCount++;
                                    var temp = this.state.exercises;
                                    temp.push({
                                        "type": "unset" + (this.keyCount),
                                        "amount": 0,
                                        "second": 0,
                                        "rounds": 0,
                                        "amountround": 0,
                                        "per": ""
                                    });
                                    this.setState({
                                        exercises: temp
                                    })
                                }}>+</button></th>
    			                <th>Total Amount</th> 
    			                {/* <th>Minute</th> */}
    			                <th>Second</th>
    			                <th>Round</th>
    			                <th>Amount</th>
    			                <th>Per</th>
    			            </tr>
    			        </thead>

                        
    			        <tbody>
				    		{((this.state.exercises.length > 0) ? this.state.exercises.map((r, i) => (
                        	  <tr key={r.type}>
                        	        <td>
                                        <select order={i} name={"type"} value={r.type} onChange={this.handleChange}>
                                            {Object.entries(allExercises).map((a) => {
                                           
                                                if (a[0] == "unset" && r.type.includes("unset") || a[0] != "unset") {
                                                    var shouldBeDisabled = this.state.exercises.some(e => e.type === a[0]);
                                                    if (a[0] == r.type) {
                                                        shouldBeDisabled = false;
                                                    }
                                                    return (<option key={a[0]} disabled={shouldBeDisabled} value={a[0]}>{a[1]}</option>)
                                                }
                                            })}
                                        </select>
                                        <button onClick={() => {
                                    var temp = this.state.exercises;
                                    temp.splice(i, 1);
                                    this.setState({
                                        exercises: temp
                                    })
                                }}><span style={{fontVariant: "small-caps"}}>remove</span></button>
                                    </td>
				    			    <td>
                                        <input order={i} type="number" name={"amount"} value={r.amount} onChange={this.handleChange}/> 
                                    </td>
				    			    {/* <td>{r.minute}</td> */}
				    			    <td onChange={this.handleChange}>
                                        <input order={i} type="number" name={"second"} value={r.second} onChange={this.handleChange}/>
                                    </td>
				    			    <td onChange={this.handleChange}>
                                        <input order={i} type="number" name={"rounds"} value={r.rounds} onChange={this.handleChange}/>
                                    </td>
				    			    <td onChange={this.handleChange}>
                                        <input order={i} type="number" name={"amountround"} value={r.amountround} onChange={this.handleChange}/>
                                    </td>
				    			    <td onChange={this.handleChange}>
                                        
                                        <input order={i} type="text" name={"per"} value={r.per} onChange={this.handleChange}/>
                                    </td>
                        	  </tr>
                        	)) : <tr>
                                <td>
                                    Add exercises with the plus above
                                </td>
                            </tr>)}
    			        </tbody>
                            
    			    </table>
                </div>
            </div>
        )
	}
}

export { 
	Imaging
};