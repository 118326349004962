import React, { useRef, useEffect } from 'react';
import { generatePath } from "react-router";
import MainPage from './MainPage';
import equal from 'fast-deep-equal'


import axios from "axios";

import {readFileDataAsBase64, connection, port, secure, host, makeReadableDate, user } from "./main.js";

import { DateTime } from "luxon";

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";
import { act } from 'react-dom/test-utils';

class Groups extends React.Component {

    static contextType = UserContext;

	constructor(props) {
		super(props);
        this.state = {
            groupName: "",
            currentGroup: {},
            editing: false,
            image: "",
            bio: " ",
            exists: true,
            presetGroups: "unset",
            creating: false,
            formalName: "f",
            deleteCounter: 5
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.invokeSocket = this.invokeSocket.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
	}

    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
        var self = this;
		
    	this.setState({
		  [name]: value,
          errorMessage: ""
		}, function () {
            // self = this;
            // this.makeRequest();
        });
	}


    handleWebSocket(message, type) {
        switch(type) {
            case "groupTrack":
                console.log(message)

                var self = this;
                if (!message) {
                    this.setState({
                        currentGroup: {},
                        deleteCounter: 5
                    }, () => {
                        self.handleEnd("check")
                    })
                } else if (typeof message == "object") {
                    
                    this.setState({
                        currentGroup: message,
                        deleteCounter: 5
                    }, () => {
                        self.handleEnd("check")
                    })
                } 
                break;
        }
    }

    handleEnd(action = "check") {
        if (action == "check" && !this.state.editing) {
            this.setState({
                bio: this.state.currentGroup.bio ? this.state.currentGroup.bio : "",
                formalName: this.state.currentGroup.formalName ? this.state.currentGroup.formalName : ""
            })
        }
        
        this.setState({
            bio: this.state.currentGroup.bio ? this.state.currentGroup.bio : "",
            editing: false
        })
        
        
    }

    componentDidMount() {
        if (this.props.match.params.groupName) {
            this.setState({
                groupName: this.props.match.params.groupName
            }, () => {
                this.makeRequest()
            })
        } else if (localStorage.getItem("groupName")) {
            this.setState({
                groupName: localStorage.getItem("groupName")
            }, () => {
                this.makeRequest()
            })
        }

        connection.on("groupTrack", (message) => this.handleWebSocket(message, "groupTrack"));
    }

    componentDidUpdate(prevProps, prevState) {
        if (!equal(prevProps, this.props)) {
            if (this.props.match.params.groupName) {
                this.setState({
                    groupName: this.props.match.params.groupName
                }, () => {
                    this.makeRequest()
                })
            } else if (localStorage.getItem("groupName")) {
                this.setState({
                    groupName: localStorage.getItem("groupName")
                }, () => {
                    this.makeRequest()
                })
            }
        }
        
    }

    componentWillUnmount() {
        this.handleWebSocket = () => {};
    }

    invokeSocket() {
        var self = this;
        return new Promise((resolve, reject) => {
            connection.invoke("GroupTrack", this.state.groupName).then(() => {
                resolve()
            }).catch(function (err) {
                setTimeout(() => {
                    self.invokeSocket();
                }, 500);
            });
        });
        
    }

    makeRequest(action = "check", userName) {

        this.invokeSocket().then(() => {
            // console.log("e");
        }).catch(() => {});
        
        var self = this;
        var dataToSend = {
            action,
            name: this.state.groupName ? this.state.groupName.toLowerCase().trim() : "",
            formalName: this.state.formalName ? this.state.formalName.toLowerCase().trim() : "",
            bio: (this.state.bio && action == "update") ? this.state.bio.trim() : "",
            picture: this.state.image ? this.state.image : "",
            special: userName
        };
        
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageGroup`, 
        dataToSend, 
            {withCredentials: true}
        )
        .then(function (response) {
            console.log(response.data)

            if (action == "check") {

                if (self.props.match.params.groupName != self.state.groupName) {
                    self.props.history.push({
                        pathname: generatePath(self.props.match.path, {groupName: self.state.groupName})
                    });
                }
    
                localStorage.setItem("groupName", self.state.groupName);
                
    
                if (response.data.data) {
                    self.setState({currentGroup: response.data.data, exists: true, errorMessage: ""}, () => {
                        self.handleEnd(action);
                    });
                } else {
                    self.setState({
                        errorMessage: "That group doesn't exist",
                        exists: false
                    }, () => {
                        self.handleEnd(action);
                    })
                    
                }

            } else {
                self.makeRequest("check");
                if (action == "create") {
                    self.setState({
                        creating: false,
                    })
                } else if (action == "delete") {
                    self.setState({
                        deleteCounter: 5
                    })
                }
            }

            
        })
        .catch(function (error) {
        })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            switch (e.target.name) {
                case "groupName":
                    this.makeRequest("check");
                    break;
            }
        }
    }


	render() {
        
        var partOfGroup;
        var requestedToJoin = false;
        var admin = false;
        var owner = false;

        if (this.state.currentGroup && !!Object.keys(this.state.currentGroup).length) {
            partOfGroup = this.state.currentGroup.users.find(i => i.name === this.context.username);
            requestedToJoin = !partOfGroup && this.state.currentGroup.requestedUsers && this.state.currentGroup.requestedUsers.length;
            admin = partOfGroup ? partOfGroup.permissionLevel == 1 || partOfGroup.permissionLevel == 2 : false;
            owner = partOfGroup ? partOfGroup.permissionLevel == 1 : false;
        }
        return (
            
            <div>
                <div className="App"
				    style={{
					    WebkitUserSelect: "none",
					    MozUserSelect: "none",
					    msUserSelect: "none",
					    userSelect: "none",
				    }}
                    className="group"
			    >
				    <MainPage title="Group">
                        <p>Groups are mainly useless at the moment, but here's how they'll work.</p>
                        <p>Enter a groupname <input type={"text"} name={"groupName"} id={"groupName"} value={this.state.groupName} onKeyDown={(e) => this._handleKeyDown(e)} onChange={(e) => {
                            this.setState({
                                presetGroups: (this.context.groups && this.context.groups.includes(e.target.value)) ? e.target.value : "unset"
                            });
                            this.handleInputChange(e);
                        }} /> <button onClick={() => this.makeRequest("check")}>OK</button></p>

                        {!this.state.creating ? 
                            (<>

                                <div>
                                    Or select a group here <select name="presetGroups" id="presetGroups" name="presetGroups" value={this.state.presetGroups} onChange={(e) => {
                                        (e.target.value != "unset") && this.setState({
                                            groupName: e.target.value
                                        }, () => {
                                            this.makeRequest("check")
                                        });
                                        this.handleInputChange(e);
                                    
                                    }}>
                                        <option value="unset">Using above ({this.state.groupName})</option>
                                        {this.context.groups.map((e) => {
                                            return <option key={e} value={e}>{e}</option>;
                                        })}

                                    </select>
                                </div>
                                <div style={{textAlign: "center", padding: "10px"}}>{this.state.errorMessage}</div>
                                {!this.state.exists && (<div onClick={() => {
                                    this.setState({
                                        creating: true,
                                        errorMessage: ""
                                    })
                                }} style={{textAlign: "center", padding: "10px", paddingTop: "0", cursor: "pointer"}}><u>Create Group</u></div>)}
                                {!this.state.editing ? 
                                    (!!Object.keys(this.state.currentGroup).length) && (
                                        <div className={`groupInfo ${this.state.currentGroup.special ? "special " + this.state.currentGroup.special : ""}`}>
                                            {admin && (
                                                <div style={{cursor: "pointer"}}><u onClick={() => {
                                                    this.setState({
                                                        editing: !this.state.editing 
                                                    })
                                                }}>Edit</u></div>
                                            )}
                                            <img className={"groupImage"} src={`${secure ? "https" : "http"}://${host}:${port}/images/groupPicture?uuid=${this.state.currentGroup.picture}&imageCount=${this.state.currentGroup.pictureCount}`} alt="Profile Picture" />
                                            <h2 className={"formalName"}>{this.state.currentGroup.formalName} {partOfGroup ? <span style={{color: "lightgreen"}}>:)</span> : ""}</h2>
                                            <div className={"name"}>({this.state.currentGroup.name})</div>
                                            <div className={"bio"}><b>{this.state.currentGroup.bio}</b> {(!partOfGroup && !requestedToJoin) && (<button onClick={() => this.makeRequest("request")}>Request to join</button>)}</div>
                                            <span className={"userListHeader"}>Members</span> {/* must make dedicated user pages */}
                                            <ul className={"userList"}>
                                                {this.state.currentGroup.users.map((e) => {
                                                    var defaultStyle = e.name === this.context.username ? {fontWeight: "bold"} : {};
                                                    var permission = ["Not a member", "Owner", "Admin", "Member"][e.permissionLevel]
                                                    var linkAddress = e.name === this.context.username ? "/you" : "/social/"+e.name;
                                                    return (
                                                        <li style={defaultStyle} key={e.name}><Link to={linkAddress}>{e.name}</Link> [{permission}]</li>
                                                    )
                                                })}
                                            </ul>
                                            {!!(this.state.currentGroup.requestedUsers && this.state.currentGroup.requestedUsers.length) && (
                                                <span>
                                                    <span className={"userListHeader"}>Requested Members</span> {/* must make dedicated user pages */}
                                                    <ul className={"userList"}>
                                                        {this.state.currentGroup.requestedUsers.map((e) => {
                                                            var defaultStyle = e.name === this.context.username ? {fontWeight: "bold"} : {};
                                                            return (
                                                                <li style={defaultStyle} key={e.name}>{e.name} ({DateTime.fromISO(e.timestamp).toRelative()}) {admin && (<span><button onClick={() => this.makeRequest("accept", e.name)}>+</button><button onClick={() => this.makeRequest("removeUser", e.name)}>Remove</button></span> )}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </span>
                                            )}
                                            {!!partOfGroup && 
                                            <div>
                                                <button onClick={() => {
                                                    this.makeRequest("leave");
                                                }}>Leave Group</button>
                                            </div>}

                                                    
                                        </div>
                                    )
                                    :
                                    (
                                        !!Object.keys(this.state.currentGroup).length) && (
                                        <div className={`groupInfo ${this.state.currentGroup.special ? "special " + this.state.currentGroup.special : ""}`}>
                                            <div style={{cursor: "pointer"}}><u onClick={() => {
                                                this.setState({
                                                    editing: !this.state.editing 
                                                })
                                            }}>Return</u></div>
                                            <p>Change group photo <input id="groupPic" type="file" accept="image/png" name={"groupPic"} onChange={(event) => {
						        		    	var self = this;
						        		    	readFileDataAsBase64(event).then((file) => {
						        		    		self.setState({
						        		    			image: file.split(",")[1]
						        		    		})
						        		    	}).catch((err) => {
						        		    		console.log(err)
						        		    	})
						        		    }}/></p>
                                            <div className={"formalName"}>Formal Name <input type={"text"} name={"formalName"} value={this.state.formalName} onChange={this.handleInputChange} /></div>
                                            <p>Bio <input type={"text"} maxLength="280" name="bio" id="bio" value={this.state.bio} onChange={this.handleInputChange} /></p>
                                            <p><button onClick={() => this.makeRequest("update")}>Update</button></p>
                                            <ul className={"userList"}>
                                                {this.state.currentGroup.users.map((e) => {
                                                    var permission = ["Not a member", "Owner", "Admin", "Member"][e.permissionLevel]
                                                
                                                    if (partOfGroup.permissionLevel < e.permissionLevel) {
                                                        return (
                                                            <li key={e.name}>{e.name} ({DateTime.fromISO(e.timestamp).toRelative()}) [{permission}] {admin && (<span><button onClick={() => this.makeRequest("removeUser", e.name)}>Remove</button>{!!(e.permissionLevel > 2) ? <button onClick={() => this.makeRequest("promote", e.name)}>Promote</button> : <button onClick={() => this.makeRequest("demote", e.name)}>Demote</button>}</span> )}
                                                            </li>
                                                        )
                                                    }
                                                    
                                                })}
                                            </ul>
                                            {(!!owner) && (
                                                <div>
                                                    <button style={{
                                                        fontWeight: !this.state.deleteCounter ? "bold" : "normal"
                                                    }} onClick={() => {
                                                        if (this.state.deleteCounter <= 0) {
                                                            this.makeRequest("delete");
                                                        } else {
                                                            this.setState({
                                                                deleteCounter: this.state.deleteCounter - 1 
                                                            })
                                                        }
                                                    }}>Delete Group {!!this.state.deleteCounter && `(Click ${this.state.deleteCounter} more times)`}</button>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                        
                        
                            </>) : (
                                <div>
                                    <div>
                                        <b>Creating group "{this.state.groupName}"</b> (<u style={{cursor: "pointer"}} onClick={() => {
                                            this.setState({
                                                creating: false,
                                                errorMessage: ""
                                            })
                                        }}>Cancel</u>)
                                    </div>
                                    <div>The above name is a "groupname", a simple, easily memorable name like a username you can give to your friends to join.</div>
                                    <div style={{paddingTop: "10px"}}>What should the formal name of the group be? <input type={"text"} id={"formalName"} name={"formalName"} value={this.state.formalName} onChange={this.handleInputChange} /><button disabled={!this.state.formalName} onClick={() => this.makeRequest("create")}>Create Group</button></div>
                                    {(this.state.errorMessage) && <div style={{textAlign: "center", padding: "10px"}}>{this.state.errorMessage}</div>}
                                </div>
                            )}

                        
                        
                    </MainPage>
                </div>
            </div>
        )
	}
}

export default Groups;