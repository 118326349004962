import React, { useRef, useEffect } from 'react';
import { generatePath } from "react-router";
import MainPage from './MainPage';
import equal from 'fast-deep-equal'

import axios from "axios";

import {readFileDataAsBase64, connection, port, secure, host, makeReadableDate, user, dateFromWeekDay, allExercises, returnPlural, getReadableTime, regimenNames } from "./main.js";

import { DateTime } from "luxon";

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";
class Sessions extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            regimen: "2021q1",
            week: 1,
            day: 1,
            date: "2021-01-04",
            activeDate: false,
            skipSeven: true,
            dayExists: false,
            mode: "viewReqs",
            modelExercises: [],
            groupOrFriends: 2,
            presetGroups: "unset",
            friendUsername: "",
            friendsToAdd: [],
            errorMessage: ""
        }

		this.handleWebSocket = this.handleWebSocket.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDaySpecific = this.handleDaySpecific.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.getModelDay = this.getModelDay.bind(this);
        this.updateExercise = this.updateExercise.bind(this);
        this.shouldIGetModelDay = this.shouldIGetModelDay.bind(this);
        this.tryAddFriend = this.tryAddFriend.bind(this);
        this.sessionOver = this.sessionOver.bind(this);
	}

    handleDaySpecific(name, value) {

        
        if (name == "day") {
            if (value > (this.state.skipSeven ? 6 : 7) ) {
                value = 1;
                this.setState({
                    week: this.state.week + 1
                });
            } else if (value < 1) {
                value = this.state.skipSeven ? 6 : 7;
                this.setState({
                    week: this.state.week - 1
                });
            }
        }
		
		
        var self = this; 
		this.setState({
			[name]: value,
		  //   activeDate: true,
		}, () => {
            self.setState({
                date: !self.state.activeDate ? dateFromWeekDay(self.state.regimen, self.state.day, self.state.week) : self.state.date
            }, () => {
                self.getModelDay(self.state.date);
            })
		});
	}


    handleInputChange(event, handlingDayWeek) {
        
		const target = event.target;
		var value;
		if (target.type == "number") {
            
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type == "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;

        if (target.id == "day" || handlingDayWeek) {
			if (value > (this.state.skipSeven ? 6 : 7) ) {
				value = 1;
				this.setState({
					week: this.state.week + 1
				});
			} else if (value < 1) {
				value = this.state.skipSeven ? 6 : 7;
				this.setState({
					week: this.state.week - 1
				});
			}
		}
		
    	this.setState({
		  [name]: value,
          
		}, function () {
            var self = this;

            self.setState({
                date: !self.state.activeDate ? dateFromWeekDay(self.state.regimen, self.state.day, self.state.week) : self.state.date
            }, () => {
                self.shouldIGetModelDay(target.className);
            })
        });
	}

    handleWebSocket(message, type) {
        // if (type == "friendExercise") {
        //     console.log(message)
            
            
        // }
    }

    componentWillUnmount() {
        // connection.invoke("FriendExercise", {
        //     action: "exit"
        // }).catch(function (err) {
        //     return console.error(err.toString());
        // });

        this.handleWebSocket = (message, type) => {};
    }


    componentDidMount() {

        // connection.on("friendExercise", (username) => this.handleWebSocket(username, "friendExercise"));

        if (this.context.session.date) {

        }
        
    }

    tryAddFriend(action = "") {

        var friend = this.state.friendUsername.toLowerCase();
        var self = this;

        var newFriendList = this.state.friendsToAdd;
        

        if (action && action == "remove") {
            newFriendList = newFriendList.filter(i => i !== friend)

            self.setState({
                friendsToAdd: newFriendList
            }, () => {
                console.log(newFriendList); 
                return;
            })
        } else {
            if (newFriendList.includes(friend)) {
                return;
            }
    
            axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/specificFriend`, 
            {
                FriendUsername: friend
            }, 
                {withCredentials: true}
            )
            .then(function (response) {
    
                if (response.status == 204) {
                    throw "";
                }
                
                newFriendList.push(friend);
    
                self.setState({
                    friendsToAdd: newFriendList,
                    errorMessage: ""
                })
                
                
            }).catch(() => {
                self.setState({
                    friendsToAdd: newFriendList,
                    errorMessage: <div><b>User not found</b></div>
                })
                
            });

        }

        
    }

    joinSession(uuid, action = "join") {
        var self = this;
        var dataToSend = {

        };

        if (action == "join") {
            dataToSend = {
                uuid
            };
        } else if (action == "create") {
            dataToSend = {
                date: this.state.date,
                groupName: this.state.groupOrFriends == 1 ? this.state.presetGroups : "",
                requestedUsers: this.state.groupOrFriends == 2 ? this.state.friendsToAdd : null
            };
        }

        dataToSend.action = action;

        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageSession`, 
	    dataToSend, 
	        {withCredentials: true}
	    )
	    .then(function (response) {

            var reqSessions = Object.keys(self.context.sessionRequests).length ? self.context.sessionRequests : {};
            delete reqSessions[uuid];
  
            self.context.updateState("sessionRequest", reqSessions);

            console.log(response.data);
            
	    }).catch((err) => {
            self.setState({
                errorMessage: <div>Error: {err.response.data.info}</div>
            })
	    });
    }

    makeRequest(action) {
        var self = this;
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageSession`, 
	    {
            action
	    }, 
	        {withCredentials: true}
	    )
	    .then(function (response) {

            if (action == "leave") {
                self.context.updateState("session", {});
            }

            console.log(response.data);
            
	    }).catch(() => {
            
	    });
    }

    shouldIGetModelDay(theClass) {
        if (theClass.includes("dayRetrieval")) {
            this.getModelDay(this.state.date);
        }
    }

    getModelDay(date = "") {
        var self = this;
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/retrieveDay`, 
        {
            date: !date ? this.context.session.date : date
		}, 
            {
                withCredentials: true
            }
        )
        .then (function (response) {
            if (response.status == 200) {

                self.setState({
                    modelExercises: response.data.data.exercises,
                    dayExists: response.data.data.day != 0
                })
            } else {
                throw "Not found";
            }
        }).catch((err) => {
            console.error(err)
            self.setState({
                modelExercises: [],
                dayExists: false
            })
        })
    }

    sessionOver() {
        this.context.updateState("session", {});
    }

    updateExercise(type, amount, second) {
        axios.post(`${secure ? "https" : "http"}://${host}:${port}/api/manageExercise`, 
        {
            info: "update",
            date: this.context.session.date,
            exercises: [{
                type,
                amount,
                second
            }]
        }, 
            {
                withCredentials: true
            }
        ).then(function (response) {

        }).catch(function (error) {

        });
    }

    componentDidMount() {
        if (this.context.session && this.context.session.date) {
            this.getModelDay();
        } else {
            this.getModelDay(this.state.date);
        }
    }

    componentDidUpdate() {
        if (this.state.modelExercises.length === 0 && (this.context.session && this.context.session.date)) {
            this.getModelDay();
        }
    }

    render() { 
        var currentSession = this.context.session;
        var requests = this.context.sessionRequests;
        var inSession = !!(this.context.session && this.context.session.date);
        return ( 
            <MainPage title={"Sessions"}>
                <div className={"sessions"}>
                    
                    {(!(inSession) && this.state.mode == "create") && (
                        <div>
                            <div><b>Create a session</b></div>
                            <div style={{marginBottom: "20px"}}>Choose a day below. <span className={"fakeLink"} onClick={() => {
                                this.setState({
                                    mode: "viewReqs"
                                })
                            }}>Return</span> </div>
                            <table id="division">
				              	<thead>
				            	  	<tr>
				                		<th>Week, Day, and Regimen</th>
				                		<th>Date</th> 
				              		</tr>
				            	</thead>
				              	<tbody>
				            	  	<tr>
				            			<td onClick={() => {
				            					this.setState({
				            						activeDate: false
				            					});
				            				}}>
				            				<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	                    				<li key="weeks">
				            						Week: <input type="number" className={"dayRetrieval"} ref={this.week} value={this.state.week} name="week" style={{width: "100%", boxSizing: 'border-box'}} id="week" disabled={this.state.activeDate} onChange={this.handleInputChange} />
                                                    <button onClick={() => this.handleDaySpecific("week", this.state.week + 1)}>⬆️</button>
                                                    <button onClick={() => this.handleDaySpecific("week", this.state.week - 1)}>⬇️</button>
        	                    				</li>
        	                    				<li key="days">
        	                    				    Day: <input type="number" className={"dayRetrieval"} ref={this.day} value={this.state.day} name="day"  style={{width: "100%", boxSizing: 'border-box'}} id="day" disabled={this.state.activeDate} onChange={this.handleInputChange} />
                                                    <button onClick={() => this.handleDaySpecific("day", this.state.day + 1)}>⬆️</button>
                                                    <button onClick={() => this.handleDaySpecific("day", this.state.day - 1)}>⬇️</button>
				            					</li>
        	                    				<li key="regimens">
        	                    				    <select id="regimen" className={"dayRetrieval"} value={this.state.regimen} name="regimen" style={{width: "100%"}} disabled={this.state.activeDate} onChange={this.handleInputChange}>
        	                    				        <option value="2021q1">2021q1: "RESURRECTION"</option>
        	                    				        <option value="2020q4">2020q4: "INCARNATION"</option>
        	                    				    </select>
        	                    				</li>
        	                				</div></ul>
				            			</td>
				                		<td onClick={() => {
				            					this.setState({
				            						activeDate: true
				            					});
				            				}}>
				            				<ul ><div style={{width: "100%", margin: "0 auto"}}>
        	                    				<li>Date: <input type="date" className={"dayRetrieval"} name="date" id="date" value={this.state.date} disabled={!this.state.activeDate} onChange={this.handleInputChange}/></li>
				            					<li><button disabled={!this.state.activeDate} onClick={() => {
                                                    this.handleDaySpecific("date", (new Date()).toISOString().substring(0, 10))
                                                
				            					}}>Today</button></li>
				            				</div></ul>
				            			</td>
				              		</tr>
				              	</tbody>
				            </table>

                            <div>
                                <div style={{marginTop: "10px"}}><b>Week {this.state.week}; Day {this.state.day}</b> - {(this.state.dayExists && "Originally set on " + makeReadableDate(this.state.date))}</div>
                                {!this.state.modelExercises.length && (this.state.dayExists ? <div>Rest day</div> : <div>Day doesn't exist</div>)}
                                <ul id="exercises">
       					        	{this.state.modelExercises.map((temp) => {

                                        var time = "";
                                
                                        if (temp.second) {
                                            time = `${getReadableTime(temp.second)}`;
                                        }
                                    
						        		if (temp.amount && !time) {
						        			return (<li key={temp.type}><b>{temp.amount} {returnPlural(temp.amount, allExercises[temp.type])}</b> ({temp.rounds} * {temp.amountround}{temp.per ? ` per ${temp.per}` : ""})</li>);
                                        
						        		} else if (time) {
						        			return (<li key={temp.type}><b>{temp.amount} {returnPlural(temp.amount, allExercises[temp.type])} {time ? time : ""}</b></li>);
						        		} else {
						        			return (<li key={temp.type}><b>{allExercises[temp.type] ? allExercises[temp.type] : temp.type}</b></li>)
						        		}
        				        	})}
      					        </ul>
                            </div>
                            
                            
                                <div>
                                    <ul style={{listStyle: "none", padding: "0"}}>
                                        {this.context.groups.length > 0 && 
                                            <li>
                                                <input type="radio" id={"gs1"} readOnly={true} value={1} onClick={this.handleInputChange} checked={this.state.groupOrFriends == 1} name="groupOrFriends"/> <label htmlFor={"gs1"}>I want to do this session with a group I'm in (choose below)</label> <select name="presetGroups" id="presetGroups" name="presetGroups" disabled={this.state.groupOrFriends != 1} value={this.state.presetGroups} onChange={this.handleInputChange}>
                                                <option value="unset" disabled={this.state.presetGroups != "unset"}>Choose group</option>
                                                {this.context.groups.map((e) => {
                                                    return <option key={e} value={e}>{e}</option>;
                                                })}
                                                </select>  
                                            </li>
                                        }
                                        <li>
                                            <input type="radio" id={"gs2"} readOnly={true} value={2} onClick={this.handleInputChange} checked={this.state.groupOrFriends == 2} name="groupOrFriends"/> <label htmlFor={"gs2"}>I want to choose the people specifically</label>
                                        </li>
                                        <li>
                                            <input type="radio" id={"gs3"} readOnly={true} value={3} onClick={this.handleInputChange} checked={this.state.groupOrFriends == 3} name="groupOrFriends"/> <label htmlFor={"gs3"}>I want to do this session on my own</label>
                                        </li>
                                    </ul>
                                </div>
                                
                            

                            {this.state.groupOrFriends == 2 && (
                                <div>
                                    Enter a username <input name={"friendUsername"} id={"friendUsername"} onChange={this.handleInputChange} value={this.state.friendUsername} /> <button onClick={this.tryAddFriend}>Add</button>
                                    {!!this.state.friendsToAdd.length && <div>You are about to request {this.state.friendsToAdd.length} person(s) to join you.</div>}
                                    <ul style={{margin: "0"}}>
                                        {this.state.friendsToAdd.map(user => (
                                            <li key={user}>{user} <button onClick={() => this.tryAddFriend("remove")}>-</button> </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div><button disabled={
                                this.state.groupOrFriends == 2 && this.state.friendsToAdd.length == 0 ||
                                this.state.groupOrFriends == 1 && this.state.presetGroups == "unset"
                            } onClick={() => this.joinSession("", "create")}>Start Session</button></div>

                            {this.state.errorMessage}

                        </div>
                        
                    )}

                    {(!(inSession) && this.state.mode == "viewReqs") && (
                        <div>
                            <div><b>Requests</b></div>
                            <div>You have {Object.entries(requests).length} session requests. <b>Sessions sometimes bug out, you should definitely try out the development REAL version. Probably found around <Link to="/info">here</Link></b></div>
                            <div><u className={"fakeLink"} onClick={() => {
                                this.setState({
                                    mode: "create"
                                })
                            }}>Create Session?</u></div>
                            <ul>
                                {Object.entries(requests).length > 0 && Object.entries(requests).map((r, i) => {
                                    var key = r[0];
                                    var value = r[1];
                                    return (
                                        <li key={i} onClick={() => {
                                            this.context.updateState("currentSessionName", key);
                                        }}>
                                            From @{value.headUser}, they are doing Week {value.week}, Day {value.day} of <span style={{fontVariant: "small-caps"}}>{regimenNames[value.regimen]}</span> ({makeReadableDate(value.date)}). <i>Request sent {DateTime.fromISO(value.timestamp).toRelative()}</i>
                                            <br/><button onClick={() => this.joinSession(value.uuid)}>Join @{value.headUser} {!!value.connectedUsers.length - 2 > 0 && `and ${value.connectedUsers.length - 2} others`}</button>
                                        </li>
                                    );
                                })}
                            </ul>

                            
                            
                        </div>
                    )}

                    {(inSession && currentSession.action == "inProgress") && (
                        <div>
                            <div className={"connectedUsers"}>Connected users: {currentSession.connectedUsers.map(x => (<span className={"user"} key={x}><b>{x}</b> ({currentSession.info[x].score})</span>))} <button onClick={() => this.makeRequest("leave")}>Leave</button></div>

                            {!!currentSession.requestedUsers.length && <div className={"requestedUsers"}>Requested users: {currentSession.requestedUsers.map(x => (<span className={"user"} key={x}><b>{x}</b></span>))}</div>} 

                            <div>
                                {this.state.modelExercises.map(r => {
                                    var possibleTime = "";
                                    var time = "";
                                
                                    if (r.second) {
                                        time = `${getReadableTime(r.second)}`;
                                    }

                                    r.amountround = r.amountround ? r.amountround : 1;
                                
                                    return (
                                        <div key={r.type} style={{paddingBottom: "10px"}}>
                                            <div style={{paddingLeft: "20px"}}>{r.amount} {returnPlural(r.amount, allExercises[r.type])} {!!time && `of ${time}`}</div>
                                            
                                 
                                            {Object.entries(currentSession.info).map(a => {
                                                var key = a[0];
                                                var value = a[1];
                                                var userExercise = value.exercises ? value.exercises.find(x => x.type == r.type) : null;
                                                var me = this.context.username == key;
                                                var percentDone = userExercise ? Math.round(100 * (userExercise.amount / r.amount)) : 0;
                                                if (userExercise) {
                                                    return (
                                                        <div key={key}>
                                                            <div style={{paddingLeft: "40px", paddingBottom: "2px"}} >{me ? <span><b>You</b> have</span> : <span><b>{key}</b> has</span>} done {userExercise.amount} ({percentDone >= 100 ? <b>{percentDone}</b> : percentDone}%)</div>
                                                            <div style={{paddingLeft: "40px"}}><progress id="file" value={percentDone} max="100"> {percentDone}% </progress></div>
                                                            {!!me && (<button onClick={() => this.updateExercise(r.type, r.amountround, r.second)} style={{marginLeft: "40px"}}>I've done {r.amountround} {returnPlural(r.amountround, allExercises[r.type])} {!!time && `(${time})`}</button>)}
                                                        </div>
                                                    )
                                                }
                                                // 
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {(inSession && currentSession.action == "over") && (
                        <div>
                            Session is now over.
                            {currentSession.endInfo == "timeout" && (
                                <div>You ran out of time for this session. You can create a new session if you'd like.</div>
                            )}
                            {currentSession.endInfo == "noMembers" && (
                                <div>Everyone left this session.</div>
                            )}
                            {currentSession.endInfo == "manual" && (
                                <div>The creator of this session destroyed it.</div>
                            )}
                            {currentSession.endInfo == "complete" && (
                                <div>Everyone gave in or completed their exercise for this day :).</div>
                            )}
                            {!!currentSession.groupName && (
                                <div><b>{currentSession.groupName}</b> gained {currentSession.groupScore} regimenscore from this session.</div>
                            )}
                            <div>You gained {currentSession.info[this.context.username].score} regimenscore from this session.</div>

                            <div>
                                <u className={"fakeLink"} onClick={this.sessionOver}>OK</u>
                            </div>
                        </div>
                    )}
                </div>


                
            </MainPage> 
        );
    }
}
 
export default Sessions;