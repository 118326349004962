import React, { useState, useContext, useEffect } from 'react';

import axios from "axios"

import {secure, port, host, user, regimenNames} from "./main"

import { useParams } from 'react-router';

import { DateTime } from "luxon";

import * as Scroll from 'react-scroll';

import {useLocation } from "react-router-dom";

import {links, retrieveDay} from "./main";

import {
	Link
} from 'react-router-dom';

import { UserContext } from "./contexts/UserContext";

const SideBar = (props) => { 
    
    var location = useLocation();

    const contextValue = useContext(UserContext);

    if (localStorage.getItem('closed') == null) {
        localStorage.setItem('closed', 'false');
    } 

    const [closed, setClosed] = useState(JSON.parse(localStorage.getItem('closed')));
    const [onSession, setOnSession] = useState(false);

    function handleClick(e) {

        setClosed(!closed);
        localStorage.setItem('closed', !closed);
    }

    function handleReclick(current) {
        if (current) {
            setClosed(!closed);
            localStorage.setItem('closed', !closed);
        }
    }

    useEffect(() => {
        setOnSession ("/" + location.pathname.split("/")[1] === ("/sessions"));

    });

    return (
            <span style={{
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
            }}>
                <div id="sidebar" className={`${closed ? "moved" : ""}`}  >
                    <div onClick={handleClick} className="mobile hideShow" >Hide</div>
                    <button onClick={handleClick} className="desktop hideShow" style={{
                        width: "20px",
                        height: "20px"
                    }}></button>
                    <ul className="linksToShow">
                        {contextValue.links.map((r) => {
                            var current = "/" + window.location.pathname.split("/")[1] === (r.link);
                            return (
                                <li className={(current ? "active" : "inactive")} key={r.link} onClick={() => handleReclick(current)}>
                                    <Link className={"sBNavLink"} to={r.link}>{r.info}</Link>
                                </li>
                            );
                        })}
                        {/* <li className={(window.location.pathname == ("/you") ? "active" : "inactive")} onClick={() => handleReclick(window.location.pathname === ("/you"))}><Link className={"sBNavLink"} to={"/you"}>{contextValue.username ? "@"+contextValue.username : ""}</Link></li> */}
                        {/* <li className={(window.location.pathname.includes("/info") ? "active" : "inactive")}><Link to={"/info"}>About</Link></li> */}
                    </ul>

                    {
                        (Object.keys(contextValue.sessionRequests).length > 0 && Object.keys(contextValue.session).length === 0) && (
                            <span>
                                <div className={onSession ? "sessiond" : ""}><i>Session Requests</i></div>
                                <ul style={{
                                    marginTop: 0
                                }}>
                                    {Object.entries(contextValue.sessionRequests).map((r, i) => {
                                        var key = r[0];
                                        var value = r[1];
                                        return (
                                            <li key={i} onClick={() => {
                                                contextValue.updateState("currentSessionName", key);
                                            }}>
                                                <Link className={"sBNavLink"} to={"/sessions"}>
                                                    from @{value.headUser}<br/>
                                                    (Week {value.week}; Day {value.day})<br/>
                                                    <span style={{fontVariant: "small-caps"}}>{regimenNames[value.regimen]}</span><br/>
                                                    
                                                    </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        )
                    }
                    
                        
                    <span className={"scrollButton"} onClick={() => {
                        // window.scrollTo({
                        //     top: 0,
                        //     behavior: "smooth"
                        // });
                        Scroll.animateScroll.scrollToTop({
                            duration: 600,
                            delay: 0,
                            // smooth: 'easeInOutQuart'
                        });
                          
                    }}>Scroll to top</span>
                    
                </div>
                <div id="mobileSelect" onClick={handleClick} className={`${closed ? "show" : ""}`}>
                    :)
                </div>
            </span>
    )
}
export default SideBar;